<template>
    <div class="find_password">
        <!-- 头部信息 -->
        <div class="find_top">
            <ul>
                <li>1.验证身份</li>
                <li>2.修改登录密码</li>
                <li>3.修改成功</li>
            </ul>
        </div>
        <hr>

        <!-- 找回密码 -->
        <div class="zhaohui">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item prop="mobile" label="手机号:">
                    <el-input type="mobile" v-model="ruleForm.mobile" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="sms_code" label="验证码:">
                    <el-input type="sms_code" v-model="ruleForm.sms_code" autocomplete="off" ></el-input>
                    <el-button type="info" id="small" @click="yanszhneg" :disabled="secdisabled">{{content}}</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">下一步</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie'
export default {
    name: 'find_password',
    data() {
        var validatemobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            }else if(!/^1[34578]\d{9}$/.test(this.ruleForm.mobile)){
                alert("请填写正确的手机号")
            }else{
                callback();
            }
        };
        var validatesms_code = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入验证码'));
            } else {
                callback();
            }
        };
        return {
           content: '发送验证码',  // 按钮里显示的内容
            secdisabled: false,
            ruleForm: {
                mobile: '',
                sms_code: '',
            },
            rules: {
                mobile: [
                    { validator: validatemobile, trigger: 'blur' }
                ],
                sms_code: [
                    { validator: validatesms_code, trigger: 'blur' }
                ],
            
            }
        }
    },
    methods: {
         yanszhneg(){
            if(this.ruleForm.mobile == '' || this.ruleForm.mobile == null){
                this.secdisabled = false;
                alert("请填写手机号")
            } else if(this.ruleForm.mobile != ''){
                var u = 60;
                var timer = setInterval(() => {
                    if(u <= 0){
                        clearInterval(timer)
                        this.secdisabled = false;
                        this.content = "获取验证码"
                    }else{
                        u--;
                        this.secdisabled = true;
                        this.content = u + "s后重新发送"
                    }
                }, 1000);

                axios.get(this.$untils.urls +'/send_sms?mobile=' + this.ruleForm.mobile + '&template_type=3')
                    .then(res => {
                        if(res.data.code == '10'){
                            Cookies.set('mobile',this.ruleForm.mobile,{ expires: 1, path: '/' })
                            alert("获取验证码成功")
                        }else{
                            alert("获取验证码失败")
                        }
                    })
            }
            
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = {
                        mobile:this.ruleForm.mobile, 
                        sms_code:this.ruleForm.sms_code,
                    }
                    axios.post(this.$untils.api + '/getpassword/?',params)
                        .then(res => {
                            if(res.data.code == 0){
                                alert("手机号验证成功")
                                this.$router.push({path:'/forgets'})
                            }else{
                                alert("手机号验证失败,请检查手机号是否填写正确")
                            }
                            if(res.data.code == null){
                                alert("手机号验证失败,请检查手机号,验证码是否填写正确")
                            }
                        })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style>
/* 外部包裹盒子样式 */
.find_password{
    width: 100%;
    height: 920px;
    font-family: "思源黑体" !important;
}


/* 头部信息样式 */
.find_password .find_top{
    width: 54%;
    height: 40px;
    margin-left: 23%;
    margin-top: 100px;
}
.find_password  .find_top ul{
    width: 100%;
    height: 40px;
    list-style: none;
}
.find_password  .find_top ul li{
    float: left;
    width: 31.3%;
    height: 40px;
    background: #eff3fa;
    text-align: center;
    line-height: 40px;
    margin-left: 3.05%;
    font-size: 14px !important;
    color: #dbdbdb;
}
.find_password  .find_top ul li:nth-child(1){
    margin-left: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    color: #5393ff !important;
}
.find_password  .find_top ul li:nth-child(3){
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.find_password hr{
    margin-top: 80px;
    color: #dbdbdb;
}




/* 找回密码样式 */
.find_password  .zhaohui {
    width: 25%;
    height: 280px;
    position: relative;
    left: 36.5%;
    top: 80px;
}

.find_password  .zhaohui .el-form{
    width: 100%;
}
.find_password  .zhaohui .el-form .el-input{
    width: 84%;
}
.find_password .el-form-item__content{
    margin-left: 0px !important;
}
.find_password .el-form-item__label{
    width: auto !important;
}
.find_password  .zhaohui  .demo-ruleForm .el-form-item .el-form-item__content .el-button {
    width: 88%;
    margin-top: 10px !important;
    margin-left: 12%;
}
.find_password  .zhaohui  .demo-ruleForm .el-form-item:nth-child(2) .el-input{
    width: 44%;
}
.find_password  .zhaohui  #small{
    width: 36%;
    margin-left: 3%;
    background: #5393ff;
    border: #5393ff;
}
.find_password  .zhaohui .is-disabled{
    background: #82848a !important;
    text-align: center !important;
}

.find_password .el-form-item__error{
    left: 15% !important;
}
</style>