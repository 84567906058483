<template>
  <div id="app">
    <!-- 头部信息 -->
    <header>
      <!-- 导航 -->
      <div class="oneone">
         <p @click="links"><img :src='this.$guide.url + "/Navigation/logo.png"' alt="" /></p>
        <ul class="list">
          <li> <router-link to="/home" tag="a">首页</router-link></li>
          <li> <router-link to="/curriculum_system" class="actives">课程体系</router-link></li>
          <li> <router-link to="/persons_team" class="actives">师资团队</router-link></li>
          <li> <router-link to="/first" class="actives">真人1v1</router-link></li>
          <li> <router-link to="/work" class="actives">作品展示</router-link></li>
          <li @mouseleave="dian ()" @mouseover="outStyle" class="binata">  
                <a class="poim">
                  编程创作
                </a>
              <ul v-show="seen" class="nots">
                  <li @click="dsda"><a href="">Scratch创作</a> </li>
                  <li><a href="python.html">Python创作</a></li>
                  <li><a href="c++.html">C++创作</a></li>
              </ul>
          </li>
          <li class="shdiao" @mouseleave="ka()" @mouseover="outStyles()">
            关于我们  
            <ul class="jianxin" v-show="seens">
              <li><a href="/about" style="color:white">公司简介</a></li>
              <li><a href="/policy_news" style="color:white">新闻动态</a></li>
            </ul>
          </li>
          <li class="jishu" @mouseleave="kas()" @mouseover="outStylesa()">
            技术支持
            <ol v-show="han">
              <li><a href="/download" style="color:white">下载中心</a></li>
              <li><a href="" style="color:white">使用说明</a></li>
              <li><a href="" style="color:white">设备检测</a></li>
            </ol>
            </li>  
        </ul>
      </div>

      <!-- 登录退出 -->
      <div class="home-login" v-if="faker" >
       <p class="login" @click="login">登录</p>
        <div class="gun"></div>
       <p class="home-zhu" @click="zhcues">注册</p>
      </div>

      <!-- 登录信息 -->
      <div class="deng-hou" v-if="uzi">
        <div class="denghou-img" @click="imageyin"><img :src="head_image" alt=""></div>
        <div class="denghou-name" >{{ name }}</div>
        <p @click="tuiyou">退出</p>
      </div>
    </header>

    <!-- 联系电话 -->
    <div class="you-ce">
      <div class="weixin" @mouseenter="yin" @mouseleave="xiaomei">
        <div class="weixin-img">
          <img :src='this.$guide.url + "/home/right-phone.png"' alt="">
        </div>
        <p class="weixin-wenzi">咨询电话</p>
      </div>
      <!-- 公众号 -->
      <div class="weixin" id="rng-uzi" @mouseenter="yang" @mouseleave="dage">
        <div class="weixin-img">
          <img :src='this.$guide.url + "/home/right-xin.png"' alt="">
        </div>
        <p class="weixin-wenzi">校区微信</p>
      </div >
      <!-- 返回顶部 -->
      <div style="cursor: pointer;" id="uzi" @click="goTop">
        <div class="fanhui-top">
          <img :src='this.$guide.url + "/home/scrlltop.png"' alt="">
        </div>
        <p class="cksas">回到顶部</p>
      </div>
      <div class="consulting" v-if="fo">
        <p>咨询电话：18916406399</p>
      </div>
      <div class="public" v-if="san">
        <div class="public-img">
          <!-- 校区悬浮div微信图标 -->
          <img :src='this.$guide.url + "/home/WeChat_mage.jpg"' alt="">
          <!-- <img src="../../public/img/wximage.jpg" alt=""> -->
        </div>
      </div>
    </div>

    <div class="zheyin" v-if="zheyinxian">
      <div class="yinying">
        <div class="medoal">
            <div class="chahao">
                <img :src='this.$untils.url + "/home/cha.png"' alt="" @click="images">
            </div>
            <p class="gerenxinxi">个人信息 </p>
            <div class="headeing">
                <div style="width:120px;height:120px;border-radius: 50%;background: #8b8b8b;margin-left: 40%;"><img :src="head_image" alt="" style="width:100%;height:100%"></div>
                <p style="margin-top:15px;font-size:17px">{{ name }}</p>
                <button class="xiutou" >修改头像</button>
            </div>
            <div class="conteal">
                <ul>
                  <li>
                    <div>
                      <img :src='this.$untils.url + "/home/mobile.png"' alt="">
                      <p>手机号: {{ mobile }}</p>
                    </div>
                  </li>
                  <li>
                      <div>
                        <img :src='this.$untils.url + "/home/bumen.png"' alt="">
                        <p>年龄: {{ age }}</p>
                      </div>
                  </li>
                  <li>
                      <div>
                      <img :src='this.$untils.url + "/home/ren.png"' alt="">
                      <p>性别: {{ sex }}</p>
                    </div>
                  </li>
                  <li>
                      <div>
                      <img :src='this.$untils.url + "/home/renshi.png"' alt="">
                      <p>用户类型: {{ user_type }}</p>
                    </div>
                  </li>
                  <li>
                      <div>
                      <img :src='this.$untils.url + "/home/gonghao.png"' alt="">
                      <p>学员类型: {{ student_type }}</p>
                    </div>
                  </li>
                  <li>
                      <div>
                      <img :src='this.$untils.url + "/home/gongzuo.png"' alt="">
                      <p>学习类型: {{ study_type }}</p>
                    </div>
                  </li>
                </ul>
            </div>
            <div class="xixian">
                <div class="chouhu">
                    <p>岗位</p>
                    <button class="xuw">名称</button>
                </div>
                
            </div>
            <p class="caozuo">操作</p>
            <ul class="mimag">
              <!-- <li @click="miam">修改密码</li> -->
              <li @click="xiugai">修改个人信息</li>
              <!-- <li>换绑手机号</li> -->
            </ul>
        </div>
      </div>
    </div>
    
    <router-view />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
export default {
  name: "App",
  data(){
    return{
      seen:false,
      seens:false,
      faker:true,
      uzi: false,
      name:'',
      han:false,
      fo:false,
      san:false,
      head_image:'',
      zheyinxian:false,
      ynone:false,
      user_type:'',
      student_type:'',
      study_type:''
    }
  },
  created(){
    this.inshow()
    this.infoshow()
  },
  methods:{
    // 获取用户信息
    infoshow(){
          var username = Cookies.get('username')
          var user_token = Cookies.get('token')
          axios.get(this.$untils.web + '/userinfo/?username=' + username + '&access_token=' + user_token).then(res =>{
            if(res.data.code == 0){
                var imag= res.data.context.head_image_url
                this.name = res.data.context.username
                this.mobile = res.data.context.mobile
                this.age = res.data.context.age
                var yonglei = res.data.context.user_type
                var xuelei = res.data.context.student_type
                var xilei = res.data.context.study_type
                var xing = res.data.context.sex
                Cookies.set('head_image',imag,{ expires: 1, path: '/' })
                if(imag == null || imag == ''){
                  this.head_image = 'http://123.206.54.57/courseware/happybinaryonline/image/head/boy.jpg'
                } else {
                  this.head_image = imag
                }

                if(xing == 1){
                  this.sex = "男"
                } else {
                  this.sex = "女"
                }

                if(yonglei == 0){
                  this.user_type = "学生"
                } else if(yonglei == 1){
                  this.user_type = "老师"
                }

                if(xuelei == 0){
                  this.student_type = "注册"
                } else if(xuelei == 1) {
                  this.student_type = "线上"
                } else {
                  this.student_type = "线下"
                }

                if(xilei == 0){
                  this.study_type = "固定"
                } else {
                  this.study_type = "预约"
                }

                console.log(this.name)
            } else {
                this.head_image = ''
            }
        })
    },

    //跳转页面
    links(){
      this.$router.push({path:'/'})
    },
    dian(){ 
      this.seen = false
    },
   
    outStyle(){
      this.seen = true
    },
    ka(){
      this.seens = false
    },
    outStyles(){
      this.seens = true
    },
       kas(){
      this.han = false
    },
    outStylesa(){
      this.han = true
    },
    login(){
      this.$router.push({path:'login'})
    },
    zhcues(){
      this.$router.push({path:'/register'})
    },
    dsda() {
      window.open('/scratch3/index.html?scene=create');
    },
    inshow(){
     var a = Cookies.get("username")
     var b = Cookies.get("token")
      if(a == '' || a == null || a == undefined || b == '' || b == null || b == undefined){
          this.uzi = false
          this.faker = true
      } else {
          this.faker = false
          this.uzi = true
      }
    },
    // 退出登录
    tuiyou(){
        var username = Cookies.get('username')
        var token = Cookies.get('token')
        console.log(username,token)
        axios.get(this.$untils.api + '/logout/?' + 'username=' + username + '&token=' + token).then(res =>{
            if(res.data.code == 0){
                alert("退出登录成功")
                Cookies.set('username','',{ expires: 0, path: '/' })  
                Cookies.set('token','',{ expires: 0, path: '/' })
                this.$router.push({path:'/home'})
                location.reload()
                this.uzi = true
                this.faker = false
            } else {
                alert("退出登录失败")
            } 
        })
    },

    imageyin(){
      this.zheyinxian = true
       this.ynone = true
    },
    images(){
        this.zheyinxian = false  
    },
    // 修改个人信息跳转
    xiugai(){
      this.$router.push({path:'/user_personal_center'})
    },

    // 回到顶部
    goTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.isShowimg = false
    },
    yin(){
      this.fo = true
    },
    xiaomei(){
      this.fo = false
    },
    yang(){
      this.san = true
    },
    dage(){
      this.san = false
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  font-family: "微软雅黑";
}
header {
  width: 100%;
  height: 100px;
  background: #5293ff;
  position: fixed;
  top: 0px;
  z-index: 9999999999;
  text-align: left !important;
  /* display: none; */
}
header .oneone{
  width: 100%;
  height: 100px;
  position: relative;
  left: 20.5%;
}
header .oneone img {
  width: auto;
  height: auto;
	max-width: 100%;
	max-height: 100%;
  position: absolute;
  left: -10%;
  top: 14px;
  cursor: pointer;
}
header .oneone .list {
  width: 56% !important;
  height: 100px;
  list-style: none;
  position: absolute;
  text-align: left !important;
  left: 7%;
  top: 0px;
  cursor: pointer;
  font-size: 16px;
}
header .oneone .list li{
  width: 11%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  float: left;
  margin-left: 1%;
}
header .oneone .list li:nth-child(1){
  margin-left: 0px;
}
header .oneone .list li .router-link-exact-active{
  display: block;
  width: 100%;
  height: 100px;
  color: white;
}
header .oneone .list li:nth-child(1){
  margin-left: 0px;
}
header .oneone .list li .jianxin{
  width: 100%;
  height: 100px;
  background: #003fa7;
  list-style: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
 header .oneone .list li .jianxin li{
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-left:  0px !important;
  color: white !important;
}
header .oneone .list .shdiao{
  color: #003fa7;
}
header .oneone .list .shdiao:hover{
  color: white;
  background: #003fa7;
}
header .oneone .list .shdiao a:hover{
  display: block;
  width: 100%;
  height: 100%;
  color: white;
}
.binata:hover a{
  display: block;
  width: 100%;
  height: 100%;
  color: white;
  background: #003fa7;
}
header .oneone .list li .nots{
  width: 100%;
  height: 150px;
  background: #003fa7 !important;
  list-style: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
 header .oneone .list li .nots li{
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-left: 0px !important;
}

header .oneone .list li:hover {
  color: white;
}
header .oneone .list li .nots li a{
  display: block;
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: white !important;
}
a{
  font-size: 16px !important;
  color: #003fa7;
  text-decoration: none;
}
.router-link-exact-active{
  color: #003fa7
}
.home-login{
  width: 150px;
  height: 50px;
  font-size: 16px;
  font-family: "微软雅黑";
  color: white;
  position: absolute;
  top: 25px;
  right: 0px;
}
.home-login p{
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.home-login .home-zhu{
  margin-left: 50px;
  margin-top: -32.5px;
}
.home-login div{
  width: 2px;
  height:15px;
  border-left: 1px solid white;
   margin-left: 50px;
  margin-top: -32.5px;
}
.deng-hou{
  width: 200px;
  height: 60px;
  color: white;
  margin-left: 88%;
  margin-top: -80px;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.denghou-img{
  width: 50px;
  height: 50px;
  border: 1px solid gainsboro;
  border-radius: 50%;
  position: relative;
  left: 60%;
  top: -5px;
  z-index: 99999999999999;
}
.denghou-img img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin-top: 10px;
}
.deng-hou>.denghou-name{
  width: 100px;
  height: 50px;
  margin-left: 18%;
  margin-top: -70px;
  cursor: pointer;
  position: relative;
  z-index: 9999999999;
  font-size: 14px;
}
.deng-hou > p{
  font-size: 14px;
  margin-top: -20px;
  position: relative;
  z-index: 999999999;
}

.jishu{
  color: #003fa7;
}
.jishu:hover{
  background: #003fa7;
  color: white;
}
header .oneone .list .jishu ol{
  width: 100%;
  height: 150px;
  background: #003fa7;
  list-style: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
header .oneone .list .jishu ol li{
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
header .oneone .list .jishu ol li a{
  display: block;
  width: 100%;
  height: 50px;
  text-align: center;
}
/* 右侧栏样式 */
.you-ce{
  width: 105px;
  height: 265px;
  box-shadow: #5293ff 0px 0px 5px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999999;
  right: 10px;
  top: 320px;
  background: rgba(255, 255, 255);
}
.weixin{
  width: 70px;
  height: 80px;
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;
}
.weixin:hover{
  color: #5293ff;
}
.weixin:hover img{
  transform:scale(1.1);
}
.weixin-img{
  width: 50px !important;
  height: 50px;
  margin-left: 8px;
}
.weixin-img img{
  width: 100%;
  height: 100%;
}
.weixin-wenzi{
  width: 100%;
  height: 20px;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}
.fanhui-top{
  width: 30px;
  height: 20px;
  margin-top: 20px;
  margin-left: 37.5px;
}
.fanhui-top img{
  width: 100%;
  height: 100%;
}
.cksas{
  font-size: 15px;
  margin-top: 5px;
  margin-left: 5px;
}
#uzi:hover{
  color: #5293ff;
}
#uzi:hover img{
  transform:scale(1.1);
}
.consulting{
  height: 60px;
  width: 250px;
  background: rgba(82, 147, 255);
  position: absolute;
  top: 20px;
  right: 100%;
  text-align: center;
  line-height: 60px;
  font-size: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
}
.public{
  width: 180px;
  height: 180px;
  position: absolute;
  top: 60px;
  left: -185px;
  background: #46BB36;
  border-radius: 10px;
}
.public-img{
  width: 140px;
  height: 140px;
  margin-top: 20px;
  margin-left: 20px;
}
.public-img img {
  width: 140px;
  height: 140px;
}
.zheyin{
  width: 100%;
  height: 938px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999999999;
  background: rgba(0,0,0,0.6);
}
/* 个人信息弹框样式 */
.yinying{
  width: 100%;
  height: 920px;
  background: rgba(0,0,0,0.6);
  position: fixed;
  top: 0px;
  z-index: 9999999999;
}
.medoal{
  width: 32.81%;
  height: 620px;
  background: #ffffff;
  position: relative;
  top: 70px;
  left: 33%;

  opacity: 1 !important;
}
.chahao{
  position: relative;
  top: 10px;
  left: 46%;
}
.chahao img{
  cursor: pointer;
}
.gerenxinxi{
  text-align: left; 
  margin-left:20px; 
  font-size:18px; 
  font-weight: 600
}
.headeing{
  width: 100%;
  height: 200px;
  margin-top: 50px;
}
.xiutou{
  width: 80px;
  height: 30px;
  font-size: 12px;
  color: white;
  background: #5393ff;
  border: 0px;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
}

.conteal{
  width: 90%;
  height: 100px;
  position: relative;
  margin-top: 25px;
  margin-left: 5%;
}
.conteal ul{
  width: 100%;
  height: 100px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.conteal ul li{
  width: 49%;
  height: 30px;
  margin-left: 0px;
}
.conteal ul li img{
  display: block;
  margin-left: 10px;
  margin-top: 5px;
}
.conteal ul li p{
  height: 30px;
  line-height: 35px;
  margin-top: -27px;
  margin-left: 40px;
  text-align: left;
  font-size: 13px;
}
.xixian{
  width: 90%;
  height: 100px;
  border-top: 1px dashed grey;
  border-bottom: 1px dashed grey;
  margin-top: 20px;
  margin-left: 5%;
}
.chouhu p{
  margin-top: 20px;
  text-align: left;
}
.xuw{
  width: 60px;
  height: 30px;
  font-size: 12px;
  color: #5393ff;
  background:  #e6efff;
  border: 1px solid #5393ff;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  display: block;
  margin-left: 0px;
}
.caozuo{
  margin-top: 10px;
  text-align: left;
  margin-left: 5%;
  font-size: 12px;
}
.mimag{
  list-style: none;
  margin-top: 8px;
  margin-left: 5%;
}
.mimag li{
  float: left;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  background:  #5393ff;
  color: white;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
}
.mimag li:nth-child(1){
  margin-left: 0px;
}
</style>
