<template>
  <div class="exhibition">
    <!-- 轮播图 -->
    <el-carousel indicator-position="outside" height="600px">
      <el-carousel-item v-for="(item, index) in items" :key="index">
        <img :src="item.image_url_workpage" alt="" />
      </el-carousel-item>
    </el-carousel>
    <iframe data-v-4
      ec8d118=""
      data-v-304
      fc807=""
      allow="geolocation; microphone; camera"
      id="iframe-player"
      src="http://code.xueersi.com/scratch3/player.html?pid=2385611&amp;version=3.0&amp;env=player&amp;fullScreen=false&amp;is_player=true"
      width="100%"
      height="400px"
      frameborder="0"
      class="player-iframe"
    >
    </iframe>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "exhibition",
  data() {
    return {
      items: []
    };
  },
  watch: {},
  mounted() {
    this.infoshow();
  },
  methods: {
    infoshow() {
      axios
        .get("http://127.0.0.1:8000/infoshow_work/")
        // 接口地址
        .then(res => {
          this.items = res.data.context.list_image_work;
          this.works = res.data.context.list_new_work;
            
        });
    }
  }
};
</script>

<style>
.curriculum_system {
  width: 100%;
  height: auto;
  position: relative;
}
#iframe-player{
    position: relative;
    z-index: 99999999;
    width:55%;
    height: 500px;
    border: 1px solid black;

}
</style>
