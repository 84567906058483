<template>
    <div class="success">
        <!-- 头部消息 -->
        <div class="find_top">
            <ul>
                <li>1.验证身份</li>
                <li>2.修改登录密码</li>
                <li>3.修改成功</li>
            </ul>
        </div>
        <hr>

        <!-- 成功信息 -->
        <p>您已成功设置密码，请使用新的密码登录</p>
        <button @click="demhlu">马上登录</button>
    </div>
</template>

<script>
export default {
    name: 'success',
    data() {

    },
    methods:{
        demhlu(){
            this.$router.push({path:'/login'})
        }
    }
}
</script>

<style>
.success{
    width: 100%;
    height: 920px;
    font-family: "思源黑体" !important;
}


/* 头部信息样式 */
.success .find_top{
    width: 54%;
    height: 40px;
    margin-left: 23%;
    margin-top: 100px;
}
.success  .find_top ul{
    width: 100%;
    height: 40px;
    list-style: none;
}
.success  .find_top ul li{
    float: left;
    width: 31.3%;
    height: 40px;
    background: #eff3fa;
    text-align: center;
    line-height: 40px;
    margin-left: 3.05%;
    font-size: 14px !important;
    color: #dbdbdb;
    cursor: pointer;
}
.success  .find_top ul li:nth-child(1){
    margin-left: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.success  .find_top ul li:nth-child(3){
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: #5393ff !important;
}
.success hr{
    margin-top: 80px;
    color: #dbdbdb;
}


.success p{
    text-align: center;
    margin-top: 80px;
    font-size: 16px;
}
.success button{
    width: 20.8%;
    height: 50px;
    margin-top: 50px;
    border-radius: 10px;
    border: 0px;
    outline: none;
    background: #5393ff;
    font-size: 16px;
    color: white;
    cursor: pointer;
}
</style>