<template>
    <div class="forgets">
            <!-- 头部信息 -->
        <div class="find_top">
            <ul>
                <li>1.验证身份</li>
                <li>2.修改登录密码</li>
                <li>>3.修改成功</li>
            </ul>
        </div>
        <hr>
            <!-- 找回密码 -->
        <div class="zhaohui">
            <div class="zhaomi">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="mobile" label="手机号：">
                        <el-input type="mobile" v-model="ruleForm.mobile" :placeholder='mobile' disabled="disabled"></el-input>
                    </el-form-item>
                    <el-form-item  prop="pass" label="新密码：">
                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入8-20位密码" maxlength="24" minlength="8"></el-input>
                    </el-form-item>
                    <el-form-item  prop="checkPass" label="重复密码：">
                        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请确认密码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie'
export default {
    name: 'forgets',
    data() {
         var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') { 
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            mobile:"",
            content: '发送验证码',  // 按钮里显示的内容
            secdisabled: false,
            ruleForm: {
                pass: '',
                checkPass: '',
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
            
            }
        }
    },
    mounted(){
        this.inshow()
    },
    methods: {
        inshow(){
            this.mobile = Cookies.get("mobile")
            console.log(this.mobile )
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = {
                        mobile:this.mobile, 
                        password:this.ruleForm.pass,
                    }
                    axios.post(this.$untils.api + '/putpassword/?',params)
                        .then(res => {
                            if(res.data.code == 0){
                                this.$router.push({path:'/success'})
                            }else{
                                alert("修改密码失败,请检查密码是否填写规范")
                            }
                            if(res.data.code == null || res.data.code == ''){
                                alert("修改密码失败,请检查密码是否填写规范")
                                return false
                            }
                        })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style>
/* 外部包裹盒子样式 */
.forgets{
    width: 100%;
    height: 920px;
    font-family: "思源黑体" !important;
}


/* 头部信息样式 */
.forgets .find_top{
    width: 54%;
    height: 40px;
    margin-left: 23%;
    margin-top: 100px;
}
.forgets  .find_top ul{
    width: 100%;
    height: 40px;
    list-style: none;
}
.forgets  .find_top ul li{
    float: left;
    width: 31.3%;
    height: 40px;
    background: #eff3fa;
    text-align: center;
    line-height: 40px;
    margin-left: 3.05%;
    font-size: 14px !important;
    color: #dbdbdb;
}
.forgets  .find_top ul li:nth-child(1){
    margin-left: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.forgets  .find_top ul li:nth-child(2){
    color: #5393ff !important;
}
.forgets  .find_top ul li:nth-child(3){
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.forgets hr{
    margin-top: 80px;
    color: #dbdbdb;
}



/* 找回密码样式 */
.forgets  .zhaohui {
    width: 25%;
    height: 280px;
    position: relative;
    left: 36.5%;
    top: 80px;
}

.forgets  .zhaohui .el-form{
    width: 100%;
}
.forgets  .zhaohui .el-form .el-input{
    width: 78%;
    margin-top: 10px;
}
.forgets .el-form-item__content{
    margin-left: 0px !important;
    text-align: right;
}
.forgets .el-form-item__label{
    width: auto !important;
    margin-top: 8px;
}

.forgets .el-button {
    width: 78%;
    margin-top: 10px !important;
    margin-left: 14%;
}
.forgets .el-form-item__error{
    left: 23% !important;
    margin-top: 5px;
}
</style>