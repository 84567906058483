<template>
  <div class="MineWorkList">
    <p class="ge">我的作品</p>
    <ul class="sun-zuo">
      <li v-for="(item, index) in items" :key="index">
          <img :src="item.coverFileUrl" alt="" @click="zhanshi(item.id)">
          <div class="tong-fang">
              <p>{{ decodeURIComponent(item.workName) }}</p>
              <p>{{ item.createTime}}</p>
          </div>
              <span @click="cha(item)"> 查看</span>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
// import Cookies from "js-cookie";
export default {
  name: "MineWorkList",
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    let _this = this;
    let datet = Date.parse(new Date()) / 1000;
    await axios
      .get(this.$yuming.urla + `/api/teaching/teachingWork/mine?_t=${datet}`)
      // 接口地址
      .then((res) => {
        _this.items = res.data.result.records;
        if (this.index) {
          _this.workid = res.data.result.records[this.index].id;
          console.log(_this.workid);
        }
        console.log(res)
      });
  },
  methods: {
    zhanshi(e) {
      console.log(e)
      window.open(`/scratch3/index.html?workId=${e}`, "_blank");
    },
    cha(es) {
      console.log(es.id)
      this.pid = es.operatingInstructions;
      let routeData = this.$router.resolve({
        path: `/scratch3/player.html?workId=${es.id}&operatingInstructions=${es.operatingInstructions}&=${es.remarksInfo} `,
        query: {
          courseId: es.courseId,
          workCover: es.workCover,
          workFile: es.workFile,
          realname: es.realname,
          workName: decodeURIComponent(es.workName),
        },
      });
      console.log(routeData);
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.MineWorkList {
  width: 100%;
  height: auto;
}
.ge {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.sun-zuo {
  width: 1145px;
  height: 800px;
  margin-top: 50px;
  margin-left: 20%;
  list-style: none;
}
.sun-zuo li {
  float: left;
  width: 255px;
  height: 270px;
  margin-left: 30px;
  margin-top: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  
}
.sun-zuo li img{
  width: 99.9%;
  cursor: pointer;
  margin-top: 0px;
}
.sun-zuo li .tong-fang{
  width: 70%;
  height: 70px;
}
.sun-zuo li .tong-fang p{
  margin-top: 10px;
}
.sun-zuo li span{
  display: block;
  width: 50px;
  height: 25px;
  border: 1px solid #0060ff;
  text-align: center;
  line-height: 25px;
   margin-left: 75%;
  margin-top: -55px;
  border-radius: 10px;
  cursor: pointer;
}
</style>
