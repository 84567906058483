<template>
    <div class="persons_team">
        <!-- 轮播图 -->
         <el-carousel :interval="5000" arrow="always" :height="bannerHeight+'px'" style="margin-top:100px;">
            <el-carousel-item v-for="(item,index) in items" :key="index">
                <div class="hoeme-img">
                    <img ref="bannerHeight" :src="item.teach_image_url" alt="" @load="imgload" width="100%">
                </div>
            </el-carousel-item>
        </el-carousel>
         <!-- 老师选择要求 -->
         <div class="zige">
             <p class="yaoqiu"><b> 快乐二进制师资选拔要求</b></p>
             <p class="tixi">快乐二进制办校以来凭借严格完善的师资选拔体系，拥有了优秀的教学体系</p>
             <div class="team">
                 <ul>
                     <li>
                         <div>
                            <p style="margin-top:18px"><img :src='this.$guide.url + "/Faculty-team/page.png"' alt=""></p>
                            <p>必备条件一</p>
                            <p><font style="color:#0060ff">毕业学校</font></p>
                            <p>优秀院校毕业</p>
                         </div>
                     </li>
                     
                     <li>
                         <div>
                             <p style="margin-top:18px"><img :src='this.$guide.url + "/Faculty-team/paper.png"' alt=""></p>
                             <p>必备条件    二</p>
                               <p><font style="color:#0060ff">优秀作品</font></p>
                               <p>五个项目以上</p>
                         </div>
                     </li>
                     <li>
                         <div>
                             <p style="margin-top:18px"><img :src='this.$guide.url + "/Faculty-team/whirte.png"' alt=""></p>
                             <p>必备条件三</p>
                               <p><font style="color:#0060ff">专业考核</font></p>
                               <p>软件完全精通</p>
                         </div>
                     </li>
                     <li>
                         <div>
                             <p style="margin-top:18px"><img :src='this.$guide.url + "/Faculty-team/Hat.png"' alt=""></p>
                             <p>必备条件四</p>
                               <p><font style="color:#0060ff">试讲满意度</font></p>
                               <p>学生满意度到达80%</p>
                         </div>
                     </li>
                     <li>
                         <div>
                             <p style="margin-top:18px"><img :src='this.$guide.url + "/Faculty-team/xin.png"' alt=""></p>
                             <p>必备条件五</p>
                               <p><font style="color:#0060ff">教学态度</font></p>
                               <p>对学员耐心，负责</p>
                         </div>
                     </li>
                 </ul>
                <div class="fengxian"></div>

             </div>
         </div>
         <div class="fadian">
            <p style="font-size:36px;padding-top:90px"><b> 优选名校名师 给孩子一个更高的出发点</b></p>
            <p style="font-size:30px;margin-top:50px">来自各个名校，超大规模的<br>讲师梦之队，给孩子一个更优质的，更高效的学习课堂</p>
            <div class="teacher-onec">
                <div class="teacher-hun">
                    <div class="weizhuang" v-if="fggd">
                        <!-- "”" +  -->
                        <p style="margin-top:10px;font-size:28px;margin-left:16%;text-align:left;color:#5293ff;"><span><font style="font-size:80px;margin-left:-10%;">“</font><p style="margin-top:-70px;">{{ this.image[0].teach_teacher_name }}</p></span></p>
                        <p style="margin-top:20px;font-size:22px;margin-left:16%;text-align:left;line-height:40px">{{ this.image[0].teach_teacher_title }}</p>
                        <div class="wei-img"><img :src="this.image[0].teach_teacher_img_url" alt=""></div>
                    </div>
                    <div class="diuke">
                        <p style="padding-top:10px;font-size:28px;margin-left:16%;text-align:left;color:#5293ff;" v-show="RNG"><span><font style="font-size:80px;margin-left:-10%;">“</font><p style="margin-top:-70px;">{{ content }}</p></span></p>
                        <p style="margin-top:20px;font-size:22px;margin-left:16%;text-align:left;line-height:40px">{{text}}</p>
                        <div class="shuo-bian" v-show="lpl"><img :src="imgsrc" alt=""></div>
                    </div>
                    <ul>
                        <li v-for="item in teachers" :key="item.teach_teacher_id" @click="clickfun(item.teach_teacher_id)">
                            <img :src="item.teach_teacher_small_img_url" alt="">
                        </li>
                    </ul>
                </div>
                
            </div>
        </div> 
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "persons_team",
    data() {
        return {
            bannerHeight:'',
            items:[],
            teachers:[],
            image:[],
            text: "",
            imgsrc: "",
            content:'',
            fggd:true,
            lpl:false,
            RNG:false
        }
    },
    watch: {},
  mounted() {
       window.scrollTo(0,0);
    this.infoshow();
    this.imgload();
    window.addEventListener('resize',() =>{
        this.bannerHeight=this.$refs.bannerHeight[0].height
        this.imgload();
    },false)
  },
    methods:{
        imgload(){
            this.$nextTick(()=>{
                this.bannerHeight=this.$refs.bannerHeight[0].height
            })
        },
        infoshow() {
            axios
                .get(this.$guide.api + "/infoshow_teachteam/")
                // 接口地址
                .then(res => {
                    this.items = res.data.context.teach_image_info;
                    this.teachers = res.data.context.teach_teacher_good_info;
                    this.image = res.data.context.teach_teacher_good_info;
                });
        },
        clickfun(e){
            let arr = this.teachers
            this.fggd = false
            this.lpl = true
            this.RNG = true
            for(let i = 0; i < arr.length; i++) {
                if (arr[i].teach_teacher_id == e) {
                    this.text = arr[i].teach_teacher_title
                    this.imgsrc = arr[i].teach_teacher_img_url
                    this.content = arr[i].teach_teacher_name
                }
            }
        },
    }
}
</script>

<style>
.persons_team{
    width: 100%;
    height: auto;
    font-family: "微软雅黑";
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}
.el-carousel__item img{
    width: auto;
	max-width: 100%;
	max-height: 100%;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
  
.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
.zige{
    width: 100%;
    height: auto;
}
.zige .yaoqiu{
    width: 100%;
    height: 50px;
    font-size: 36px;
    margin-top: 100px;
    text-align: center;
    line-height: 50px;
}
.zige .tixi{
    width: 100%;
    height: 50px;
    font-size: 30px;
    margin-top: 20px;
    text-align: center;
    line-height: 50px;
}
.zige .team{
    width: 100%;
    height: auto;
    background: #2e7dff;
    margin-top: 50px;
}
.zige .team ul{
    width: 63%;
    height:  auto;
    margin-left: 18.5%;
    list-style: none;
    display: flex;
}
.zige .team ul li{
    width: 18.5%;
    height: auto;
    background: white;
    margin-left: 1.8%;
    margin-top: 75px;
}
.zige .team ul li:nth-child(1){
    margin-left: 0px;
}
.zige .team .fengxian{
    width: 100%;
    height: 75px;
}
.zige .team ul li div{
    width: 100%;
    height: 6.4rem;
    position: relative;
}
.zige .team ul li div p{ 
    font-size: 18px !important;
}
.zige .team ul li div p:nth-child(2){
    width:100%;
    height:80px; 
    background:#94bcff;
    position: absolute;
    top:120px;
    text-align: center;
    line-height: 80px;
    font-size: 22px;
}
.zige .team ul li div p:nth-child(3){
    width: 100%;
    height: 80px;
    position: absolute;
    top: 200px;
    text-align: center;
    line-height: 80px;
    font-size: 22px;
}
.zige .team ul li div p:nth-child(4){
    width: 100%;
    height: 55px;
    position: absolute;
    bottom: 20px;
    text-align: center;
    line-height: 35px;
    font-size: 22px;
}
.fadian{
    width: 100%;
    height: auto;
    background: #EFF5FF !important;
}
.teacher-onec{
    width: 62.5%;
    height: auto;
    margin-left: 18.75%;
    margin-top: 50px;
}

.teacher-hun{
    width: 83%;
    height: auto;
    margin-left: 17%;
}
.teacher-hun .diuke{
    width: 100%;
    height: 300px;
    border-radius: 10px;
    box-shadow:#2e7dff 0px 0px 5px;
}
.diuke .shuo-bian{
    width: 31%;
    height: 410px;
    margin-left: -20.5%;
    margin-top: -85px;
} 
.diuke .shuo-bian img{
    width: 100%;
    height: 100%;
}
.teacher-hun ul{
    width: 61%;
    height: auto;
    margin-left: 26.5%;
    margin-top: 30px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.teacher-hun ul li{
    width: 21.311%;
    height: 180px;
    margin-left: 4.4%;
    margin-bottom: 100px;
}
.teacher-hun ul li img{
    width: 100%;
    height: 100%;
}
.teacher-hun ul li:nth-child(1){
    margin-left: 0px;
}
.weizhuang{
    width: 51.6%;
    height: 300px;
    position: absolute;
}
.wei-img{
    width: 31%;
    height: 410px;
    margin-left: -20.5%;
    margin-top: -85px;
} 
.wei-img img{
    width: 100%;
    height: 100%;
}
</style>