import Vue from 'vue'
import App from './App'
import router from './router'
import Router from 'vue-router'
import VueResource from 'vue-resource'
import Vuex from "vuex";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import  'lib-flexible/flexible'
import yuming from './../src/router/yuming'
import guide from './../src/router/guide'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import untils from './../src/router/untils'

Vue.prototype.$untils = untils
  
Vue.use(VideoPlayer)

Vue.prototype.$guide = guide
Vue.prototype.$yuming = yuming

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
};

const originalReplace = Router.prototype.replace
Router.prototype.replace= function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueResource)

Vue.use(ElementUI)

Vue.prototype.axios = axios;
// Vue.prototype.BASE_URL1 = 'http://123.206.54.57:6005';
// Vue.prototype.BASE_URL2 = 'http://175.24.249.4:8081';

Vue.use(axios);

Vue.use(Vuex);

Vue.config.productionTip = false;

let vm = new Vue({
  router, // 注入到根实例中
  el: '#app',
  render: h => h(App)
})

Vue.use({vm})