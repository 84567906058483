<template>
  <div class="shouye">
    <!-- 轮播图 -->
    <el-carousel :interval="5000" arrow="always" :height="bannerHeight + 'px'">
      <el-carousel-item v-for="(home, index) in homes" :key="index">
        <div class="hoeme-img">
          <img
            ref="bannerHeight"
            :src="home.index_image_url"
            alt=""
            @load="imgload"
            width="100%"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 学习编程的理由 -->
    <div class="home-li">
      <span class="hai-a"><b> 孩子学习编程为什么要选择快乐二进制</b></span>
      <p class="liuda">六大理由</p>
      <ul>
        <li>
          <img :src='this.$guide.url + "/home/Elite.png"' alt="" class="chou-si" />
          <span
            ><img
              :src='this.$guide.url + "/home/spot.png"'
              alt=""
            />&nbsp;&nbsp;&nbsp;&nbsp;<font>九层严选的名校精英师资</font></span
          >
        </li>
        <li>
          <img :src='this.$guide.url + "/home/customized.png"' alt="" class="chou-si" />
          <span
            ><img
              :src='this.$guide.url + "/home/spot.png"'
              alt=""
            />&nbsp;&nbsp;&nbsp;&nbsp;1对1定制学习方案</span
          >
        </li>
        <li>
          <img :src='this.$guide.url + "/home/research.png"' alt="" class="chou-si" />
          <span
            ><img
              :src='this.$guide.url + "/home/spot.png"'
              alt=""
            />&nbsp;&nbsp;&nbsp;&nbsp;国际化教研团队和科学学习方法</span
          >
        </li>
        <li>
          <img :src='this.$guide.url + "/home/study.png"' alt="" class="chou-si" />
          <span
            ><img
              :src='this.$guide.url + "/home/spot.png"'
              alt=""
            />&nbsp;&nbsp;&nbsp;&nbsp;在线学习新风尚</span
          >
        </li>
        <li>
          <img :src='this.$guide.url + "/home/competition.png"' alt="" class="chou-si" />
          <span
            ><img
              :src='this.$guide.url + "/home/spot.png"'
              alt=""
            />&nbsp;&nbsp;&nbsp;&nbsp;升学,竞赛和出国的全能助力</span
          >
        </li>
        <li>
          <img :src='this.$guide.url + "/home/create.png"' alt="" class="chou-si" />
          <span
            ><img
              :src='this.$guide.url + "/home/spot.png"'
              alt=""
            />&nbsp;&nbsp;&nbsp;&nbsp;激活创造力的趣味FBL课堂</span
          >
        </li>
      </ul>
    </div>
    <!-- 老师资源 -->
    <div class="shizi">
      <div class="shizi-a">
        <span class="hai-a"><b>为了孩子学有所成 我们认真选拔好的老师</b></span>
        <ul class="teacher-a">
          <li>每位老师都师出名校</li>
          <li>丰富的编程专业知识</li>
          <li>优秀的讲解内容</li>
        </ul>
      </div>
      <div class="yiqi">
        <iframe src="/wenjian/index.html" frameborder="0"></iframe>
      </div>
    </div>
    <!-- 真人1v1 -->
    <div class="gao-a">
      <span class="hai-a"><b>真人在线1对1 让学习更高效</b></span>
      <p class="xiangni">真人1对1的教学模式</p>
      <p>给每一位学员制定符合其自身情况的教学方案</p>
      <div class="henghua">
        <ul>
          <li @mouseleave="likai" @mouseenter="fan">
            <img
              :src='this.$guide.url + "/home/exclusive.png"'
              alt=""
              height="100%"
              v-bind:class="img !== true ? 'cao' : 'ni'"
            />
            <img
              :src='this.$guide.url + "/home/guarantee.png"'
              alt=""
              height="102%"
              width="100%"
              class="chou"
            />
          </li>
          <li @mouseleave="likail" @mouseenter="rens">
            <img
              :src='this.$guide.url + "/home/save.png"'
              alt=""
              height="100%"
              width="100%"
              v-bind:class="hello !== true ? 'si' : 'red'"
            />
            <img
              :src='this.$guide.url + "/home/Review.jpg"'
              alt=""
              height="100%"
              width="100%"
              class="chou"
            />
          </li>
          <li @mouseleave="buzou" @mouseenter="wy">
            <img
              :src='this.$guide.url + "/home/playback.png"'
              alt=""
              height="100%"
              width="100%"
              v-bind:class="jack !== true ? 'wang' : 'yuan'"
            />
            <img
              :src='this.$guide.url + "/home/promote.jpg"'
              alt=""
              height="100%"
              width="100%"
              class="chou"
            />
          </li>
          <li @mouseleave="huilai" @mouseenter="cui">
            <img
              :src='this.$guide.url + "/home/efficiency.png"'
              alt=""
              height="100%"
              width="100%"
              v-bind:class="uz !== true ? 'yao' : 'xin'"
            />
            <img
              :src='this.$guide.url + "/home/effect.jpg"'
              alt=""
              height="100%"
              width="100%"
              class="chou"
            />
          </li>
        </ul>
      </div>
      <div class="tiankong"></div>
    </div>
    <!-- 都在学的编程课 -->
    <div class="da-da">
      <div class="mi-mi">
        <span class="hai-a"
          ><b
            ><font style="color: #ffd800">3417万</font>孩子都在学的编程课</b
          ></span
        >
        <p>定制化课程，专业挖掘中国孩子的天分</p>
        <div class="liumang" >
          <ul>
            <li @click="add_scratch">Scratch图形化编程</li>
            <li @click="add_python">Python编程</li>
            <li @click="add_cpp">C++编程</li>
          </ul>
          <div class="top-1" v-if="scratch_video">
            <div class="vide">
                <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
                  
                  >
                </video-player>
            </div>
            <div class="vide_to">
              <img :src='this.$guide.url + "/home/scratch.png"' alt=""/>
            </div>  
          </div>
          <div class="top-1" v-if="python_video">
            <div class="vide">
              <video-player class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOption"
                >
              </video-player>
            </div>
            <div class="vide_to">
              <img :src='this.$guide.url + "/home/python.png"' alt="" />
            </div>
          </div>
          <div class="top-1" v-if="cpp_video">
            <div class="vide">
              <video-player class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptiona"
                
                >
              </video-player>
            </div>
            <div class="vide_to">
              <img :src='this.$guide.url + "/home/cpp.png"' alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div class="tiankong"></div>
    </div>
    <!-- 作品显示 -->
    <div class="chuang">
      <span class="hai-a"
        ><b
          >创作与不同的知识相结合&nbsp;&nbsp;&nbsp;让孩子发挥自己的创造力</b
        ></span
      >
      <p class="ding">
        通过PBL项目式教学，
        <br />孩子们可以将所学的复杂知识融会贯通，做到学以致用
      </p>
      <div class="huang_se">
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <div>
              <img :src="item.works_url" alt="" />
            </div>
            <span>
              <p style="font-size: 22px;">{{ item.works_name }}</p>
              <p style="font-size: 16px;">学员：{{ item.kid_name }}</p>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 参照美国csta -->
    <div class="can">
      <span class="hai-a"
        ><b>
          参照美国CSTA标准&nbsp;&nbsp;&nbsp;&nbsp;打造跟适合中国孩子的编程课</b
        ></span
      >
      <p style="margin-top: 45px;">
        课程参照美国计算机教师协会 K-12教育标准（CSTA K-12 CS standards）
      </p>
      <p>
        依据孩子学习需求，编写不同体系的阶梯式课程，制定更适合中国孩子的教学体系
      </p>
      <div>
        <img :src='this.$guide.url + "/home/system.png"' alt="" width="100%" />
      </div>
      <p class="tiankong"></p>
    </div>
    <!-- 远见之选 -->
    <div class="job">
      <div class="job_img">
        <div class="ditu">
          <img :src='this.$guide.url + "/home/bottom-image2.jpg"' alt="" />
        </div>
        <div style="width:100%;height:50px;background:white;"></div>
        <div class="ditu2">
          <img :src='this.$guide.url + "/home/bottom-image1.gif"' alt="" />
        </div>
      </div>
    </div>
    <!-- 底部备注信息 -->
    <div class="what">
      <div class="gong_zhong">
        <div class="gong_Xinxi">
          <div class="gong_img">
            <img :src='this.$guide.url + "/home/kuaile.png"' alt="" >
          </div>
          <div class="gongzhonghao">
            <img :src='this.$guide.url + "/home/WeChat_mage.jpg"' alt="" width="100%" height="68%" >
            <span class="footsize">扫码领福利</span>
            <!-- <br> -->
            <!-- <span class="footsize">公众号</span> -->
          </div>
          <div class="lianxi">
            <ul>
              <li>
                <div class="dian_tu"><img :src='this.$guide.url + "/home/phonenumber.png"' alt=""></div>
                <p>18916406399</p>
              </li>
              <li>
                <div class="dian_tu"><img :src='this.$guide.url + "/home/mailer.png"' alt=""></div>
                <p>happybinary@163.com</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ul>
        <li><a href="https://beian.miit.gov.cn/" style ="color:white">沪ICP备20008719号-3 快乐二进制少儿编程</a></li>
        <li>公司名称：上海火岩数字技术有限公司<font style="margin-left:20px;margin-right:20px;">|</font> 公司地址：上海浦东新区新场古镇汇友路70号</li>
      </ul>
    </div>
    <!-- 固定的信息 -->
    <div class="caonima btn_run" v-if="local">
      <p style="font-size:30px;color:black">
        免费领取价值<font style="color:#f7ff05">258元</font>的试听课
      </p>
      <ul>
        <li>
          <input type="name" v-model="form.name" placeholder="孩子姓名" />
        </li>
        <li>
          <input type="age" v-model="form.age" placeholder="年龄" />
        </li>
        <li>
          <input
            type="phone"
            v-model="form.namphonenumbere"
            placeholder="手机号"
          />
        </li>
      </ul>
      <el-button type="primary" @click="onSubmit">立即预约</el-button>
    </div>
    <!-- 预约提是信息 -->
    <div class="tishi" v-show="yinsh">
      <button @click="yin_shi">确定</button>
    </div>
    <div class="shi-baixian" v-if="shibai">
      <p style="font-size:14px;margin-top:110px;">请输入孩子名字，年龄和联系电话哟~</p>
      <button @click="shi_bai">确定</button>
    </div>
    <div class="yi_han" v-if="yihan">
      <p style="font-size:14px;margin-top:110px;">手机号码格式有误，请输入正确的手机号码~</p>
      <button @click="yi_han">确定</button>
    </div>
    <div class="shenbain" v-if="shenbain">
      <p style="font-size:14px;margin-top:110px;">名字只能是汉字和英文字母不能包含特殊符号，请重新输入~</p>
      <button @click="shenbains">确定</button>
    </div>
    <div class="shuzis" v-if="shuzis">
      <p style="font-size:14px;margin-top:110px;">年龄不能为空，年龄只能为数字，请重新输入~</p>
      <button @click="shuzi">确定</button>
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
// import Cookies from "js-cookie";
export default {
  name: "shouye",
  data() {
    return {
      yinsh:false,
      shibai:false,
      yihan:false,
      shenbain:false,
      shuzis:false,
      bannerHeight: "",
      homes: [],
      teachs: [],
      items: [],
      works_url: "",
      local: true,
      form: {
        name: "",
        age: "",
        namphonenumbere: "",
      },
      img: false,
      hello: false,
      jack: false,
      uz: false,

      // 视频
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false  , // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: this.$guide.url + '/home/company_introduce/vedio/scratch_introduce_vedio.mp4' // url地址
        }],
        poster: this.$guide.url + '/home/company_introduce/cover/scratch_cover.jpg', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      playerOption: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: this.$guide.url + '/home/company_introduce/vedio/python_introduce_vedio.mp4' // url地址
        }],
        poster: this.$guide.url + '/home/company_introduce/cover/python_cover.jpg', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      playerOptiona: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: this.$guide.url + '/home/company_introduce/vedio/cpp_introduce_vedio.mp4' // url地址
        }],
        poster: this.$guide.url + '/home/company_introduce/cover/cpp_cover.jpg', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      scratch_video:true,
      python_video:false,
      cpp_video:false,
    };
  },
  methods: {
    bindColor: function (index) {
        console.log(index)
        this.isSelect = index
    },
    add_python(){
      this.scratch_video=false
      this.python_video=true
      this.cpp_video=false
    },
    add_scratch(){
      this.scratch_video=true
      this.python_video=false
      this.cpp_video=false
    },
    add_cpp(){
      this.scratch_video=false
      this.python_video=false
      this.cpp_video=true
    },
    infoshow() {
      axios
        .get(this.$guide.api + "/infoshow_index/")
        // 接口地址
        .then((res) => {
          this.homes = res.data.context.index_image_info;
          this.teachs = res.data.context.index_teacher_info;
          this.items = res.data.context.index_works_info;
          if (this.works_url == "") {
            this.works_url = this.items[0].works_url;
          }
          console.log(this.homes);
        });
    },
    changeImg(e) {
      this.works_url = e;
    },
    onSubmit() {
      axios
        .get(
          this.$guide.api + "/appointment/?mobile=" + this.form.namphonenumbere + "&age=" + this.form.age + "&name=" + this.form.name
        )
        // 接口地址
        .then((res) => {
          //成功信息
          if(res.data.code == "0"){
            this.yinsh = true
          }
          //失败的各种提示
          if(this.form.name == '' & this.form.namphonenumbere == '' & this.form.age == ''){
             this.shibai = true
             return
          }
          if(this.form.name == '' || !/^[\u0391-\uFFE5A-Za-z]+$/.test(this.form.name)){
             this.shenbain = true
          }
          if(this.form.age == '' || !/^[0-9]*$/.test(this.form.age)){
             this.shuzis = true
             return
          }
          if(this.form.namphonenumbere ==  "" || !/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[7]))\d{8}$/.test(this.form.namphonenumbere)){
             this.yihan = true
             return
          }
        });
    },
    fan() {
      this.img = true;
    },
    rens() {
      this.hello = true;
    },
    wy() {
      this.jack = true;
    },
    cui() {
      this.uz = true;
    },
    // si(){

    likai() {
      this.img = false;
    },
    likail() {
      this.hello = false;
    },
    buzou() {
      this.jack = false;
    },
    huilai() {
      this.uz = false;
    },
    // 导航
    imgload() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
        // console.log(this.bannerHeight)
      });
    },
    play(index) {
      // 播放当前 其它都暂停
      const video = this.$refs.video;
      console.log(video)
      this.index = index;
      video[index].play();
      video.forEach((item, ind) => {
        if (ind !== index) {
          item.pause();
        }
      });
    },
    yin_shi(){
      this.yinsh = false
    },
    shi_bai(){
      this.shibai = false
    },
    yi_han(){
      this.yihan = false
    },
    shenbains(){
      this.shenbain = false
    },
    shuzi(){
      this.shuzis =false
    }
  },
    mounted() {
       window.scrollTo(0,0);
        this.infoshow();
        window.addEventListener("scroll", this.btn_run);
        this.imgload();
        window.addEventListener(
        "resize",
        () => {
            this.bannerHeight = this.$refs.bannerHeight[0].height;
            this.imgload();
        },
        false
        );
    },
    created() {
        // this.inshow()
        // this.name = Cookies.get("names")
        var sef = this;
        window.onscroll = function() {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        var windowHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight =
            document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight < scrollHeight - 5) {
            sef.local = true;
        } else {
            sef.local = false;
        }
        };
    },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

/* 首页样式 */
.shouye {
  width: 100%;
  height: auto;
  position: relative;
  top: 100px;
  font-family: "微软雅黑";
}
/* 提示样式 */
.tishi{
  width: 26%;
  height: 200px;
  position: fixed;
  top: 300px;
  left: 37%;
  z-index: 99999999;
  background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/home/make.png");
  background-size: 100% 100%;
}
.tishi button{
  width: 80px;
  height: 30px;
  background: #5293ff;
  color: white;
  font-size: 14px;
  border: 0px;
  border-radius: 5px;
  position: relative;
  top: 150px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
/* 失败样式 */
.shi-baixian{
    width: 26%;
    height: 200px;
    position: fixed;
    top: 300px;
    left: 37%;
    z-index: 99999999;
    background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/home/appointment.png");
    background-size: 100% 100%;
}
.shi-baixian button{
  width: 80px;
  height: 30px;
  background: #5293ff;
  color: white;
  font-size: 14px;
  border: 0px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
/* 第二种失败的样式 */
.yi_han{
    width: 26%;
    height: 200px;
    position: fixed;
    top: 300px;
    left: 37%;
    z-index: 99999999;
    background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/home/appointment.png");
    background-size: 100% 100%;
}
.yi_han button{
  width: 80px;
  height: 30px;
  background: #5293ff;
  color: white;
  font-size: 14px;
  border: 0px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.shenbain{
    width: 26%;
    height: 200px;
    position: fixed;
    top: 300px;
    left: 37%;
    z-index: 99999999;
    background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/home/appointment.png");
    background-size: 100% 100%;
}
.shenbain button{
  width: 80px;
  height: 30px;
  background: #5293ff;
  color: white;
  font-size: 14px;
  border: 0px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.shuzis{
    width: 26%;
    height: 200px;
    position: fixed;
    top: 300px;
    left: 37%;
    z-index: 99999999;
    background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/home/appointment.png");
    background-size: 100% 100%;
}
.shuzis button{
  width: 80px;
  height: 30px;
  background: #5293ff;
  color: white;
  font-size: 14px;
  border: 0px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
/* 为什么选择我们 */
.home-li {
  width: 62%;
  height: auto;
  margin-left: 20%;
  margin-top: 100px;
}
.home-li .hai-a {
  display: block;
  font-size: 36px;
  color: #5293ff;
  text-align: center;
}
.liuda {
  margin-top: 40px;
  font-size: 40px;
  color: #000000;
  position: relative;
  left: 0;
}
.home-li ul {
  width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.home-li ul li {
  width: 28%;
  height: 380px;
  margin-left: 8%;
  margin-top: 30px;
  border-radius: 10px;
}
.home-li ul li:nth-child(1) {
  margin-left: 0px;
}
.home-li ul li:nth-child(4) {
  margin-left: 0px;
  margin-bottom: 70px;
}
.home-li ul li .chou-si {
  border-radius: 10px;
  width: 100%;
  height: 85%;
  box-shadow: gainsboro 0px 0px 2px;
}
.home-li ul li span {
  display: block;
  margin-top: 20px;
  font-size: 20px;
}
/* 老师资源 */
.shizi {
  width: 100%;
  height: auto;
  background: #5293ff;
  margin-top: 30px;
}
.shizi .yiqi {
  width: 62%;
  height: auto;
  margin-left: 19%;
}
.shizi .yiqi iframe {
  width: 100%;
  height: 430px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.shizi-a {
  width: 62%;
  height: auto;
  position: relative;
  left: 19%;
}
.shizi-a .hai-a {
  display: block;
  font-size: 34px;
  color: white;
  text-align: center;
  padding-top: 50px;
}
.shizi-a .teacher-a {
  width: 100%;
  height: auto;
  list-style: none;
  margin-top: 40px;
  display: flex;
  margin-left: 5%;
}
.shizi-a .teacher-a li {
  margin-left: 120px;
  color: white;
  font-size: 26px;
}
.shizi-a .teacher-a li:nth-child(1) {
  margin-left: 30px;
}

/* 真人1v1 */
.gao-a {
  width: 62%;
  height: auto;
  margin-left: 19%;
}
.gao-a .hai-a {
  display: block;
  font-size: 36px;
  color: #5293ff;
  text-align: center;
  margin-top: 50px;
}
.xiangni {
  margin-top: 30px;
}
.gao-a p {
  font-size: 30px;
}
.gao-a p:first-child {
  margin-top: 50px !important;
}
.gao-a .henghua {
  width: 100%;
  height: auto;
  margin-top: 50px;
}
.gao-a .henghua ul {
  width: 100%;
  height: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.gao-a .henghua ul li {
  width: 25%;
  height: 400px;
  list-style: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.gao-a .henghua ul li:nth-child(1) {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}
.gao-a .henghua ul li:nth-child(4) {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}
.gao-a .henghua ul li .chou {
  margin-top: 1px;
}
.gao-a .henghua ul li .cao {
  position: absolute;
  transition: all 1s;
  top: 0px;
}
.gao-a .henghua ul li .ni {
  transition: all 1s;
  position: absolute;
  top: -400px;
}
.gao-a .henghua ul li .si {
  position: absolute;
  transition: all 1s;
  top: 0px;
}
.gao-a .henghua ul li .red {
  transition: all 1s;
  position: absolute;
  top: -400px;
}
.gao-a .henghua ul li .wang {
  position: absolute;
  transition: all 1s;
  top: 0px;
}
.gao-a .henghua ul li .yuan {
  transition: all 1s;
  position: absolute;
  top: -400px;
}
.gao-a .henghua ul li .yao {
  position: absolute;
  transition: all 1s;
  top: 0px;
}
.gao-a .henghua ul li .xin {
  transition: all 1s;
  position: absolute;
  top: -400px;
}
.gao-a .tiankong {
  margin-top: 100px;
  border: white;
}
/* 都在学的编程课 */
.da-da {
  width: 100%;
  height: auto;
  background: #5293ff;
}
.mi-mi {
  width: 62%;
  height: auto;
  margin-left: 19%;
  border: 1px solid #5293ff;
}
.mi-mi .hai-a {
  display: block;
  font-size: 36px;
  color: white;
  text-align: center;
  margin-top: 50px;
}
.mi-mi p {
  margin-top: 40px;
  color: white;
  font-size: 30px;
}
.mi-mi .liumang {
  width: 100%;
  height: auto;
  position: relative;
  left: 0px;
  top: 45px;
}
.mi-mi .liumang ul {
  width: 100%;
  height: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.mi-mi .liumang ul li {
  width: 16.6%;
  height: 50px;
  background: white;
  color: black;
  font-size: 18px;
  line-height: 50px;
  margin-left: 24.8%;
  border-radius: 10px;
  cursor: pointer;
}
.mi-mi .liumang ul li:nth-child(1) {
  margin-left: 0px;
}
.top-1 {
  width: 100%;
  height: auto;
  margin-top: 50px;
}
.vide {
  width: 56.66%;
  height: 400px;
  background: white;
  border-radius: 15px;
  margin-left: 0%;
}

.video-player {
  width: 94%;
  height: 90%;
  border: 1px solid white;
  border-radius: 15px;
  margin-left: 3%;
  position: relative;
  top: 5% !important;
}
.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3 {
  height: 100%;
  height: 100%;
}
.vide_to {
  width: 39.1%;
  height: 400px;
  background: white;
  border-radius: 15px;
  margin-left: 60.5%;
  margin-top: -400px;
}
 @media screen and (max-width: 2400px) { 
.vide_to {height: 315px !important;margin-top: -315px;} 
.vide{height: 315px;}
}
@media screen and (min-width: 1920px) { 
.vide_to {height: 400px !important; margin-top: -400px;} 
.vide{height: 400px;}
} 
.vide_to img{
  width: 100%;
  height: 100%;
}
/* 内容 */
.da-da .tiankong {
  width: 100%;
  height: 50px;
  margin-top: 50px;
}
/* 部分作品展示 */
.chuang {
  width: 99.9%;
  height: auto;
  border: 1px solid white;
  background: white;
}
.chuang span {
  display: block;
  margin-top: 100px;
  font-size: 36px;
  color: #5293ff;
}
.chuang .ding {
  font-size: 30px;
  margin-top: 40px;
  color: black;
}
.huang_se {
  width: 62%;
  height: auto;
  margin-top: 50px;
  margin-left: 19%;
}
.huang_se ul {
  width: 100%;
  height: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.huang_se ul li {
  width: 30%;
  height: 355px;
  margin-left: 4.7%;
  box-shadow: 0px 0px 4px gainsboro;
  border-radius: 10px;
}
.huang_se ul li:nth-child(1) {
  margin-left: 0px;
}
.huang_se ul li:nth-child(4) {
  margin-left: 0px;
  margin-top: 50px;
}
.huang_se ul li:nth-child(5) {
  margin-top: 50px;
}
.huang_se ul li:nth-child(6) {
  margin-top: 50px;
}
.huang_se ul li div {
  width: 95%;
  height: 290px;
  margin-left: 2.5%;
  margin-top: 10px;
}
.huang_se ul li div img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.huang_se ul li span {
  display: block;
  width: 100%;
  height: 50px;
  position: relative;
  top: -97px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #000000;
}
.huang_se ul li span p {
  line-height: 50px;
  width: 50%;
}
.huang_se ul li span p:nth-child(2) {
  margin-left: 50%;
  margin-top: -50px;
}

/* 参照美国csta */
.can {
  width: 60%;
  height: auto;
  margin-left: 20%;
  border: 1px solid white;
}
.can .hai-a {
  width: 100%;
  display: block;
  font-size: 36px;
  color: #5293ff;
  text-align: center;
  margin-top: 100px;
}
.can p {
  font-size: 25px;
  margin-top: 10px;
}
.can div {
  width: 100%;
  margin-top: 50px;
}
/* 远见之选 */
.job {
  width: 100%;
  height: auto;
  background: white;
  margin-top: 100px;
}
.job_img {
  width: 100%;
  height: auto;
}
.ditu {
  width: 100%;
  height: 480px;
}
.ditu img {
  width: 100%;
  height: 100%;
}
.ditu2 {
  width: 100%;
  height: 415px;
}
.ditu2 img {
  width: 100%;
  height: 100%;
}

/* 底部信息备注 */
.what {
  width: 100%;
  height: auto;
  background: #373e55;
  margin-top: 50px;
}
.what .gong_zhong{
  width: 100%;
  height: 250px;
  background: #5293ff;
}
.what .gong_zhong .gong_Xinxi{
  width: 70%;
  height: 99%;
  border: 1px solid #5293ff;
  margin-left: 15%;
  margin-top: 0px;
}
.gong_img{
  width: 33.6%;
  height: 130px;
  margin-top: 60px;
}
.gongzhonghao{
  width: 11.8%;
  height: 210px;
  margin-top: -170px;
  margin-left: 50%;
}
.gongzhonghao img {
  border-radius: 8px;
}
/* 设置二维码下面的字体样式 */
.footsize {
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin: 3px 0;
  display: inline-block;
  letter-spacing: 0.2em;
}
.lianxi{
  width: 18%;
  height: 115px;
  margin-top: -160px;
  margin-left: 78%;
}
.lianxi ul{
  width: 100%;
  height: 100%;
  list-style: none;
}
.lianxi ul li{
  width: 100%;
  margin-top: 30px;
}
.lianxi ul li:nth-child(1){
  margin-top: 0px !important;
}
.lianxi ul li .dian_tu{
  width: 45px;
  height: 45px;
  position: relative;
  left: 0%;
}
.lianxi ul li .dian_tu img{
  width: 100%;
  height: 100%;
}
.lianxi ul li p{
  width: 75%;
  text-align: left;
  position: relative;
  left: 60px;
  top: -45px;
}
.what ul {
  width: 100%;
  height: 100px;
  color: white;
  cursor: pointer;
  list-style: none;
}
.what ul li {
  width: 100%;
  height: 40px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  line-height: 40px;
}
.what ul li:nth-child(1) {
  margin-left: 0px;
  margin-top: 15px;
}

/* 固定的信息 */
.caonima {
  width: 100%;
  height: 120px;
  position: fixed;
  bottom: 0px;
  z-index: 9999;
  background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/home/fixed.jpg");
}
.caonima p {
  width: 20%;
  height: 150px;
  line-height: 150px;
  margin-left: 12%;
}
.caonima ul {
  width: 50%;
  height: 150px;
  margin-left: 35%;
  margin-top: -150px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.caonima ul li {
  width: 33%;
  height: 150px;
  margin-top: 10px;
}
.caonima ul li input {
  width: 80%;
  height: 50px;
  border: 0px;
  margin-top: 30px;
  padding-left: 10px;
  border-radius: 5px;
}
.shouye .el-button {
  width: 100px;
  height: 50px;
  position: relative;
  margin-left: 80% !important;
  top: -108px;
  border-radius: 10px;
}
#tab-0 {
  margin-left: 8% !important;
}

.shouye .el-tabs {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: auto;
  border: 0px !important;
  box-shadow: 0px 0px 0px 0px;
}
.shouye .el-tabs__header {
  width: 100%;
  height: auto;
  background: 0 !important;
  border: 0px !important;
}
.shouye .el-tabs__nav-wrap {
  width: 100%;
  height: auto;
}
.shouye .el-tabs__nav-scroll {
  width: 100%;
  height: auto;
}
.shouye .el-tabs__nav {
  width: 100%;
  height: 50px;
}
.shouye .el-tabs__item {
  height: 50px !important;
  width: 16.6% !important;
  margin-left: 23.1% !important;
  border: 09px !important;
  line-height: 50px !important;
  font-size: 18px !important;
  color: black;
  border-radius: 10px !important;
  background: white;
}
.shouye .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  border: 0px !important;
}
.shouye #tab-0 {
  margin-left: 0px !important;
  position: relative;
  left: 0px !important;
  padding: 0px !important;
}
.vjs-poster{
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
@media screen and (max-width:2400px) {
    .gongzhonghao{
        height: 175px;
        margin-top: -145px;
    }
}
@media screen and (min-width:1920px) {
    .gongzhonghao{
        height: 210px;
        margin-top: -170px;
    }
}
</style>
