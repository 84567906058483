<template>
    <div class="python_player">
       <div class="zhan-zuom">
            <div class="meiren">
                <div class="naotou">
                    <div class="poyuan"><img :src="hers.work_tiny_logo" alt="" width="100%" height="100%"></div>
                    <div class="lom">{{ hers.works_name }}</div>
                    <div class="poois">{{ hers.work_name }}&nbsp;&nbsp;&nbsp;{{ hers.work_time }}</div>
                </div>
                <div class="shi-zuoxa">
                    <iframe src="/scratch/xinde_tui.html" width="100%" height="70%"  ></iframe>
                    <ul>
                        <li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                            <img
                            :src="huoshen"
                            alt=""
                            />
                            <span>{{  hers.work_watch }}</span>
                        </li>
                        <li>
                            <img
                                :src="shuishen"
                                alt=""
                            />
                            <span>{{  hers.work_comment }}</span>
                        </li>
                        <li>
                            <img :src="leishen" alt=""/>
                            <span>{{  hers.work_prefer }}</span>
                        </li>
                    </ul>
                </div>
                <div class="zhan-beizhuc">
                    <div class="zhan-img">
                        <img :src="hers.work_tiny_logo" alt="">
                        <div class="zhan-ji">{{ hers.work_name }}</div>
                        <div class="zhan-nian">{{ hers.work_age }}岁&nbsp;&nbsp;&nbsp;{{ hers.work_grade }}年级&nbsp;&nbsp;&nbsp;学习时长{{ hers.work_studytime }}月</div>
                    </div>
                    <div class="zhan-ont">
                        <textarea name="" id="" cols="30" rows="10" placeholder="Python作品" v-model="beisj"></textarea>
                    </div>
                    <div class="zhan-con">
                        <textarea name="" id="" cols="30" rows="10" placeholder="操作说明" v-model="shuosm"></textarea>
                    </div>
                </div>
            </div>
            <div class="zhan-pingp">
               <div class="pinglun">
                   <div class="qing-lunazas">
                       <div class="ping-yuan"><img :src="hers.work_tiny_logo" alt="" width="100%" height="100%"></div>
                       <input type="text" v-model="inputvalue">
                       <div class="ping-dian" @click="fabiao(hers.work_id)">发表评论</div>
                   </div>
                   <ul v-if="youta">
                        <li v-for="(ping,index) in lun" :key="index">
                            <div class="guying">
                                <img :src="hers.work_tiny_logo" alt="">
                            </div>
                            <div class="dameng">
                                <p style="font-size:14px;">{{ hers.work_name }}</p>
                                <p style="font-size:16px;margin-left:10px; ">{{ ping.comment_content }}</p>
                                <p style="font-size:12px;color: grey;">{{ ping.comment_time }}</p>
                                
                            </div>
                            
                        </li>
                    </ul>
                    <div class="meiyou" v-if="meiyou">
                        <img src="../../assets/未标题-1.jpg" alt="">
                    </div>
                    <!-- 评论分页 -->
                     <el-pagination
                        class="madan"
                        v-if="shuzui"
                        :current-page.sync="current_page"
                        background
                        layout="prev, pager, next"
                        :total=total
                        :page-size="10"
                    >
                    </el-pagination>
                    <div class="qing-lu">
                       <div class="ping-yuan"><img :src="hers.work_tiny_logo" alt="" width="100%" height="100%"></div>
                       <input type="text" v-model="inputvalue">
                       <div class="pin-dian" @click="fabiao(hers.work_id)">发表评论</div>
                   </div>
                </div>
                <Info />
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from "axios";
export default {
    components:{
        Info: () => import(`./../public_a/recommend`)
    },
    name: "python_player",
    data(){
        return {
            huoshen:'',
            shuishen:'',
            leishen:'',
            hers:[],
            duan:[],
            lun:[],
            youta:true,
            inputvalue:'',
            dsdsd:"/pythonzhanshi.html",
            xianshi:'',
            beisj:'',
            shuosm:'',
            total:this.total,
            current_page: 1,
            meiyou:false,
            shuzui:true
        }
    },
    watch: {
        current_page(to, from) {
            if (from != 0) {
                this.qinglun(this.tabsNames)
                console.log("dssdssd")
            }
        },
    },
    created(){
        this.hers =JSON.parse(Cookies.get("two")) 
        console.log(this.hers)
        this.jinru()
        this.zhanshow()
        this.shishow()
        this.qinglun(this.tabsNames)
        this.zhogshen()
    },
    mounted(){
        window.scrollTo(0,0);
    },
    methods:{
        zhogshen(){
            this.huoshen = this.$guide.url + '/works-display/kan.png'
            this.shuishen = this.$guide.url + '/works-display/pinlun.png'
            this.leishen = this.$guide.url + '/works-display/zan.png'
        },
        jinru(){
            this.beisj = this.hers.workpage_works_remarks
            this.shuosm = this.hers.workpage_operating_instructions
        },
        zhanshow() {
            axios
                .get(this.$guide.api + "/infoshow_index/")
                // 接口地址
                .then(res => {
                    this.items = res.data.context.index_image_info;
                });
        },
       shishow (){
            axios
                .get(this.$guide.api + "/infoshow_work_recommend/")
                    .then(res => {
                        this.duan = res.data.context.workpage_list_recommend;
                    })
        },
        fabiao(c){
            var a = Cookies.get("xiaojiejie")
            if(a){
                var userid =Cookies.get("userid")
                axios
                    .get(this.$guide.api + "/infoshow_work_addcomment/?works_id=" + c + "&user_id=" + userid +"&floor=" + 1 + "&comment_content=" + this.inputvalue + "&comment_id_f1=" + -1 )
                        .then(res => {
                            console.log(res)
                            if(res.data.code == 0){
                                this.inputvalue = ""
                            }
                        })
            }else {
                alert("登录之后才能评论，请登录！")
            }
        },
        qinglun(){
            axios   
                .get(this.$guide.api + `h/infoshow_work_commentstatus/?works_id=${this.hers.work_id}&current_page=${this.current_page}&page_size=10`)
                    .then(res =>{
                        if(res.data.code == 5003){
                            this.meiyou = true
                            this.shuzui = false
                        } else {
                            this.lun = res.data.context.current_page_comment.page_comment_list
                            console.log(res.data.context.current_page_comment.page_comment_list)
                            this.total = res.data.context.summary_comment
                        }
                    })
        }
    }
};
</script>

<style>
*{
    margin: 0;
    padding: 0;
}
.python_player .zhan-zuom .zhan-pingp .zhan-tuirs{
    position: absolute;
    top: 10px;
    left: 63%;
}
.python_player{
    width: 62%;
    height: auto;
    margin-left: 19%;
}
.python_player .zhan-zuom{
    width: 100%;
    height: auto;
    margin-top: 110px;
}
.python_player .zhan-zuom .meiren{
    width: 100%;
    height: 650px;
}
.python_player .zhan-zuom .meiren .shi-zuoxa{
    width: 60%;
    height: 480px;
}
.naotou{
    width: 80%;
    height: 80px;
    margin-left: 3%;
    margin-top: 10px;
}
.naotou .poyuan{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 15px;
    cursor: pointer;
}
.lom{
    width: 120px;
    height: 30px;
    font-size: 18px;
    margin-top: -50px;
    margin-left: 50px;
}
.poois{
    width: 200px;
    height: 30px;
    font-size: 14px;
    margin-left: 40px;
}
.shi-zuoxa iframe{
    width: 100%;
    height: 480px;
    border: 0px;
    margin-left: 0px;
}
 .shi-zuoxa ul{
    list-style: none;
    width: 80%;
    height: 30px;
    margin-top: 25px;
    margin-left: 10%;
    display: flex;
    flex-wrap: wrap;
}
.shi-zuoxa ul li{
    margin-left: 8%;
}
.shi-zuoxa ul li span{
    display: block;
    margin-top: -23px;
    margin-left: 65px;
}
.python_player .zhan-zuom .meiren .zhan-beizhuc{
    width: 35%;
    height: 520px;
    margin-left: 63%;
    margin-top: -520px;
    background: white;
}
.zhan-beizhuc .zhan-img{
    width: 100%;
    height: 90px;
    background: #ebf2ff !important;
}
.zhan-beizhuc .zhan-img img{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-left: -210px;
}
.zhan-beizhuc .zhan-img .zhan-ji{
    width: 120px;
    height: 30px;
    margin-left: 110px;
    margin-top: -80px;
    font-size: 16px;
    color: #333333;
}
.zhan-beizhuc .zhan-img .zhan-nian{
    width: 190px;
    height: 30px;
    margin-left: 110px;
    margin-top: 0px;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
}
.zhan-beizhuc .zhan-ont{
    width: 100%;
    height: 180px;
    margin-top: 30px;
}
.zhan-beizhuc .zhan-ont textarea{
    width: 100%;
    height: 100%;
    border: 0px;
    background-color: #e2edff;
}
.zhan-beizhuc .zhan-con{
    width: 100%;
    height: 180px;
    margin-top: 30px;
}
.zhan-beizhuc .zhan-con textarea{
    width: 100%;
    height: 100%;
    border: 0px;
    background-color: #e2edff;
}
.zhan-pingp{
    width: 100%;
    height: auto;
    position: relative;

}
.zhan-pingp .pinglun{
    width: 61.4%;
    height: auto;
}
.meiyou{
    width: 90%;
    height: 300px;
}
.meiyou img{
    width: 100%;
    height: 100%;
}
.qing-lunazas{
    width: 100%;
    height: 90px;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    margin-top: 0px;
}
.ping-yuan{
    width: 50px;
    height: 50px;
    margin-top: 20px;
}
.ping-yuan img{
    border-radius: 50%;
}
.qing-lunazas input{
    width: 80%;
    height: 50px;
    background: #f4f5f7;
    border: 0px;
    position: relative;
    top: -50px;
}
.ping-dian{
    width: 50px;
    height: 50px;
    background: #00c0ff;
    font-size: 18px;
    position: relative;
    font-family: "楷体";
    top: -100px;
    left: 92%;
    cursor: pointer;
}
/* 下面的评论 */
.qing-lu{
    width: 100%;
    height: 90px;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    margin-top: 30px;
}
.ping-yuan{
    width: 50px;
    height: 50px;
    margin-top: 20px;
}
.ping-yuan img{
    border-radius: 50%;
}
.qing-lu input{
    width: 80%;
    height: 50px;
    background: #f4f5f7;
    border: 0px;
    position: relative;
    top: -50px;
}
.pin-dian{
    width: 50px;
    height: 50px;
    background: #00c0ff;
    font-size: 18px;
    position: relative;
    font-family: "楷体";
    top: -100px;
    left: 92%;
    cursor: pointer;
}
/* 下面的评论 */
.pinglun ul{
    width: 90%;
    height: auto;
    margin-top: 10px;
    margin-left: 5%;
    list-style: none;
}
.pinglun ul li{
    width: 100%;
    height:70px;
}
.pinglun ul li .guying{
    width: 50px;
    height: 50px;
    border: 1px solid gainsboro;
    border-radius: 50%;
    margin-top: 30px;
}
.pinglun ul li .guying img{
    margin-top: 10px;
}
.pinglun ul li .dameng{
    width: 80%;
    height: 75px;
    margin-top: -60px;
    margin-left: 15%;
    text-align: left;
}
.pinglun ul li .dameng p{
    margin-top: 10px;
}
.pinglun ul li .dameng p:nth-child(1){
    margin-top: 0px;
    color: black;
}
.stage-header_stage-button-icon_3zzFK{
    display: none !important;
}
.madan{
    width: 90%;
    height: 30px;
}
.madan .el-pager li{
    width: 30px;
    height: 100%;
    margin-top: -10px !important;
}
</style>
