import Vue from "vue";
import Router from "vue-router";
import Index from "@/views/Index";
import home from "@/views/home";
import user_personal_center from "@/views/user_personal_center";
import curriculum_system from "@/views/curriculum_system";
import persons_team from "@/views/persons_team";
import first from "@/views/first";
import exhibition from "@/views/exhibition";
import about from "@/views/about";
import login from "@/components/login";//登录
import logins from "@/components/logins";//手机号登录
import register from "@/components/register";//注册 
import find_password from "@/components/find_password" //找回密码
import forgets from "@/components/forgets" //新的密码
import success from "@/components/success" //修改成功
import MineWorkList from "@/views/account/center/MineWorkList"; //标题
import work from "@/views/work";
import recommend from "@/views/public_a/recommend"; 
import policy_news from "@/views/policy_news"; 
import download from "@/views/download"; 
// two
import python_player from "@/views/tui_chong/python_player";
import cpp_player from "@/views/tui_chong/cpp_player";


Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Index",
      component: Index,
      redirect: '/home',
      children: [
        //首页
        {
          path: "/home",
          name: "home",
          component: home,
        },
        //课程体系
        {
          path: "/curriculum_system",
          name: "curriculum_system",
          component: curriculum_system
        },
        // 师资团队
        {
          path: "/persons_team",
          name: "persons_team",
          component: persons_team
        },
        //真人1对1
        {
          path: "/first",
          name: "first",
          component: first
        },
        {
          path: "/about",
          name: "about",
          component: about
        },
        {
          path: "work",
          name: "work",
          component: work
        },
        {
          path: '/python_player',
          name: 'python_player',
          component: python_player
        },
        {
          path: '/cpp_player',
          name: 'cpp_player',
          component: cpp_player
        },
        {
          path: '/policy_news',
          name: 'policy_news',
          component: policy_news
        },
        // 技术支持
        {
          path: '/download',
          name: 'download',
          component: download
        },
      ]
    },
    // //作品展示下的作品1
   
    // //作品展示下的作品2
    {
      path: "/exhibition",
      name: "exhibition",
      component: exhibition
    },
    //关于我们
    {
      path: "/about",
      name: "about",
      component: about
    },
    //密码登录
    {
      path: "/login",
      name: "login",
      component: login
    },
    // 手机号登录
    {
      path: "/logins",
      name: "logins",
      component: logins
    },
    //注册
    {
      path: "/register",
      name: "register",
      component: register
    },
    // 找回密码
    {
      path: "/find_password",
      name: "find_password",
      component: find_password
    },
    // 修改密码
    {
      path: "/forgets",
      name: "forgets",
      component: forgets
    },
     // 修改成功
     {
      path: "/success",
      name: "success",
      component: success
    },
    {
      path: '/account/center',
      name: 'MineWorkList',
      component: MineWorkList
    },
    {
      path: '/recommend',
      name: 'recommend',
      component: recommend
    },
    {
      path: '/user_personal_center',
      name: 'user_personal_center',
      component: user_personal_center
    },
  ]
});

