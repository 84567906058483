var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"context"},[_c('div',{staticClass:"context_a"},[_vm._m(2),_vm._m(3),_c('ul',{staticClass:"vm_ul"},[_c('li',[_c('img',{attrs:{"src":this.$guide.url + "/about/Company-profile/advantage-1.png","width":"100%","height":"100%"}})]),_vm._m(4),_vm._m(5),_c('li',[_c('img',{attrs:{"src":this.$guide.url + "/about/Company-profile/advantage-2.png","alt":""}})]),_c('li',[_c('img',{attrs:{"src":this.$guide.url + "/about/Company-profile/advantage-3.png","alt":""}})]),_vm._m(6),_vm._m(7),_c('li',[_c('img',{attrs:{"src":this.$guide.url + "/about/Company-profile/advantage-4.png","alt":""}})])])])]),_c('div',{staticClass:"news"},[_vm._m(8),_c('ul',{staticClass:"liziqi"},_vm._l((_vm.informations),function(information,index){return _c('li',{key:index},[_c('div',{staticClass:"new_img"},[_c('img',{attrs:{"src":information.news_image_url,"alt":"","height":"100%","width":"100%"}})]),_c('div',{staticClass:"new_wenzi"},[_c('span',[_vm._v(_vm._s(information.news_title))]),_c('span',[_vm._v(_vm._s(_vm._f("ellipsis")(information.news_content)))]),_c('div',{staticClass:"divs"},[_c('p',[_vm._v("时间："+_vm._s(information.news_year)+"-"+_vm._s(information.news_date))]),_c('span',{on:{"click":function($event){return _vm.shiting(information.news_id)}}},[_c('div',[_vm._v("查看内容")]),_c('img',{attrs:{"src":_vm.baoshen,"alt":""}})])])])])}),0),_c('el-pagination',{attrs:{"current-page":_vm.current_page,"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":6},on:{"update:currentPage":function($event){_vm.current_page=$event},"update:current-page":function($event){_vm.current_page=$event}}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_as"},[_c('div',{staticClass:"as_guan"},[_c('p',[_vm._v("公司简介")]),_c('p',[_vm._v("COMP ANY PROFILE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_yu"},[_c('p',{staticClass:"guan_wo"},[_vm._v("关于我们")]),_c('div',{staticClass:"guanwen"},[_c('p',[_vm._v(" 快乐二进制青少儿编程是中国知名少儿编程教育品牌。是专门为6-16岁青少儿提供的线下(面对面授课),线上（真人一对一 授课）趣味编程教育，目的是提升孩子智力，开拓思维，培养孩子成为全方位的高素质，高科技创新人才！ ")]),_c('p',[_vm._v(" 快乐二进制青少儿编程为全球6-16岁青少儿少儿研发了图形化编程平台及系列编程课。通过学习，孩子们可以创作出奇妙的游 戏、软件、动画等，全方位锻炼逻辑思维、创造力等核心素养。目前，快乐二进制青少儿编程平台已研发了超过19种品类编程课， 并与全国一流名牌大学，中学，小学等在内的全国11599余所学校与教育机构达成合作。 ")]),_c('p',[_vm._v("2019年，快乐二进制青少儿编程被评选为年度影响力在线教育品牌！")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"kuai_a"},[_c('b',[_vm._v("快乐二进制的优势")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fang_one"},[_c('ul',[_c('li',[_c('div',{staticClass:"box_wen"},[_vm._v("01")])]),_c('li',[_c('div',{staticClass:"box_wen"},[_vm._v("02")])]),_c('li',[_c('div',{staticClass:"box_wen"},[_vm._v("03")])]),_c('li',[_c('div',{staticClass:"box_wen"},[_vm._v("04")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"left"},[_c('div',{staticClass:"right_a"}),_c('span',{staticClass:"spanon"},[_vm._v("先进的教育体系")]),_c('div',{staticClass:"left_a"},[_c('p',[_vm._v("课程参照美国计算机教师协会 K-12教育标准 ")]),_c('p',[_vm._v("（CSTA K-12 CS standards） ")]),_c('p',[_vm._v("依据孩子学习需求，编写不同体系的阶梯式课程，制定更适 ")]),_c('p',[_vm._v("合中国孩子的教学体系")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"left"},[_c('div',{staticClass:"right_a"}),_c('span',[_vm._v("强大的师资团队")]),_c('div',{staticClass:"left_a"},[_c('p',[_vm._v("来自各个名校、超大规模的讲师梦之队，给孩子一个更优质")]),_c('p',[_vm._v("更高效的学习课堂")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"left"},[_c('div',{staticClass:"right_a"}),_c('span',[_vm._v("坚实的硬件设施")]),_c('div',{staticClass:"left_a"},[_c('p',[_vm._v("科技化的教学工具，让学员可以跟着课堂讲解即时操作，更")]),_c('p',[_vm._v("快的对所讲的知识融会贯通。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"left"},[_c('div',{staticClass:"right_a"}),_c('span',[_vm._v("优秀的学习资源")]),_c('div',{staticClass:"left_a"},[_c('p',[_vm._v("快乐二进制给孩子提供最优质的学习资源，让孩子能够及时")]),_c('p',[_vm._v("的学习到最新的知识，创造更好的世界。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('b',[_vm._v("新闻动态")])])
}]

export { render, staticRenderFns }