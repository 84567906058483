<template>
    <div class="first" :style="'height:'+fullHeight+'px;'" style="margin-top: 100px;"> 
        <!-- 轮播图 -->
        <el-carousel :interval="5000" arrow="always" :height="bannerHeight+'px'">
            <el-carousel-item v-for="(first,index) in firsts" :key="index">
                <div class="hoeme-img">
                    <img ref="bannerHeight" :src="first.one_image_url" alt="" @load="imgload" width="100%">
                </div>
            </el-carousel-item>
        </el-carousel>
        <!-- 1v1制定课程 -->
        <div class="lvl">
            <span><b>开启一对一专属定制课程</b></span>
            <p>1对1教学，专属定制，给孩子打造更适合的教学方案</p>
            <img :src='this.$guide.url + "/Human/1v1.jpg"' alt="">
        </div>
        <!-- 1v1让学习更高效 -->
        <div class="goa_xiao">
            <span><b>真人在线1对1   让学习更高效</b></span>
            <p>真人1对1的教学模式，</p>
            <p>给每一位学员定制符合其自身情况的教学方案</p>
            <img :src='this.$guide.url + "/Human/efficiency.jpg"' alt="">
        </div>
        <!-- 最后的图 -->
        <div class="ui-tu">
            <ul>
                <li><img :src='this.$guide.url + "/Human/study-1.png"' alt=""></li>
                <li><img :src='this.$guide.url + "/Human/study-2.png"' alt=""></li>
                <li><img :src='this.$guide.url + "/Human/study-3.png"' alt=""></li>
                <li><img :src='this.$guide.url + "/Human/study-4.png"' alt=""></li>
                <li><img :src='this.$guide.url + "/Human/study-5.png"' alt=""></li>
                <li><img :src='this.$guide.url + "/Human/study-6.png"' alt=""></li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "first",
    data() {
        return {
            bannerHeight:'',
            firsts:[],
            fullHeight: document.documentElement.clientHeight
        }
    },
    watch: {
        fullHeight (val) {//监控浏览器高度变化
            if(!this.timer) {
                this.fullHeight = val
                this.timer = true
                let that = this
                setTimeout(function (){
                    that.timer = false
                },400)
            }
            
        }
    },
    mounted() {
         window.scrollTo(0,0);
        this.infoshow();
        this.get_bodyHeight()
        this.imgload();
        window.addEventListener('resize',() =>{
            this.bannerHeight=this.$refs.bannerHeight[0].height
            this.imgload();
        },false)
    },
    methods: {
        infoshow() {
            axios
                .get(this.$guide.api + "/infoshow_1v1_image/")
                // 接口地址
                .then(res => {
                    this.firsts = res.data.context.one_image_info;
                    console.log(this.firsts)
                });
        },
        get_bodyHeight () {//动态获取浏览器高度
            const that = this
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight
                    that.fullHeight = window.fullHeight
                })()
            }
        },
        // 导航
        imgload(){
            this.$nextTick(()=>{
                this.bannerHeight=this.$refs.bannerHeight[0].height
            })
        }
    }
}
</script>

<style >
.first{
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
.el-carousel__item{
    width: 100%;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.el-carousel__item img{
    width: auto;
    height: auto;
	max-width: 100%;
	max-height: 100%;
}
.lvl{
    width: 60%;
    position: relative;
    left: 20%;
}
.lvl span{
    font-size: 28px;
    display: block;
    margin-top: 100px;
}
.lvl p{
    font-size: 22px;
    margin-top: 30px;
}
.lvl img{
    margin-top: 60px;
    width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.goa_xiao{
    width: 60%;
    position: relative;
    left: 20%;
    top: 100px;
}
.goa_xiao span{
    font-size: 28px;
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
}
.goa_xiao p{
    font-size: 22px;
    margin-top: 10px;
    width: auto;
    height: auto;
}
.goa_xiao img{
    margin-top: 40px;
    width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.ui-tu{
    width: 60%;
    position: relative;
    left: 20%;
    top: 3.266667rem;
}
.ui-tu ul{
    width: 100%;
    height: 280px;
    list-style: none;
}
.ui-tu ul li{
    width: 33.3%;
    height: 100%px;
    float: left;
}
.ui-tu ul li img{
    width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
/* .zhe{
    width: 100%;
    height: 100%;
    background-color:black;
    opacity: 0.7;
    color: white;
    font-size: 30px;
    text-align: center;
    line-height: 380px;
} */
</style>