<template>
    <div class="zhan-tuirs">
        <p>相关推荐</p>
        <ul>
            <li v-for="(duans,index) in duan" :key="index">
                <img :src="duans.workpage_recommend_image_url" alt="" height="100%"  @click="chufei(index)">
                <div class="tui-jian">
                    <p style="font-size:16px;color:#333333;">{{ duans.workpage_works_name }}</p>
                    <p style="font-size:14px;color:#666666;">{{ duans.workpage_kid_name }}</p>
                    <p style="font-size:14px;color:#666666;">sdsadasdsadsadsadsa</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
export default {
    name: "python_player",
    data(){
        return {
            duan:[],
        }
    },
    created(){
        this.shishow()
    },
    methods:{
        shishow (){
            axios
            .get(this.$guide.api + "/infoshow_work_recommend/")
            .then(res => {
                this.duan = res.data.context.workpage_list_recommend;
            })
        },
        chufei(s){
            var uzia = this.duan[s]
            var work_age = uzia.workpage_kid_age                        //年龄
            var work_grade = uzia.workpage_kid_grade                    //班级
            var work_name = uzia.workpage_kid_name                      //作者名字
            var work_studytime = uzia.workpage_kid_studytime            //学习时长
            var work_image_url = uzia.workpage_recommend_image_url      //图片
            var work_code = uzia.workpage_new_works_code                    //作品文件
            var work_comment = uzia.workpage_works_comment              //评论数量
            var work_id = uzia.workpage_works_id                        //作品id
            var work_logo  = uzia.workpage_works_logo                   //用户logo
            var works_name = uzia.workpage_works_name                   //作品名字
            var work_operating_instructions = uzia.workpage_operating_instructions            //操作说明
            var work_prefer = uzia.workpage_works_prefer   //点赞数量
            var work_remarks = uzia.workpage_works_remarks                 //备注
            var work_sign = uzia.workpage_works_sign           //作品类型
            var works_teaching_id = uzia.workpage_works_teaching_id                      //文件id
            var work_time  = uzia.workpage_works_time            //时间
            var work_tiny_logo = uzia.workpage_works_tiny_logo   //tiny_logo
            var work_url = uzia.workpage_works_url                 //url
            var work_watch  = uzia.workpage_works_watch           //观看次数
            var mdu = {
                "work_age": work_age,"work_grade":work_grade,"work_name":work_name,
                "work_studytime":work_studytime,"work_image_url":work_image_url,"work_code":work_code,
                "work_comment":work_comment,"work_id":work_id,"work_logo":work_logo,"works_name":works_name,
                "work_operating_instructions":work_operating_instructions,"work_prefer":work_prefer,
                "work_remarks":work_remarks,"work_sign":work_sign,"works_teaching_id":works_teaching_id,
                "work_time":work_time,"work_tiny_logo":work_tiny_logo,"work_url":work_url,"work_watch":work_watch
            }
            Cookies.set("two", mdu, { expires: 1, path: "/" });
            var echas= uzia.workpage_new_works_code
            Cookies.set("echas", echas, { expires: 1, path: "/" });
            if(uzia.workpage_works_sign == "python"){
                this.$router.push({path: "/python_player"}) 
                location.reload() 
            }else if(uzia.workpage_works_sign == "cpp"){
                this.$router.push({path:"/cpp_player"})
                location.reload() 
            }else if(uzia.workpage_works_sign == "scratch"){
            
                window.location.href="scratch3/scratch_player.html"
            }
        }
    }
}
</script>

<style >
.zhan-tuirs{
    width: 35%;
    height: auto;
}
.zhan-tuirs p{
    width: 100px;
    height: 20px;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
}
.zhan-tuirs ul{
    width: 100%;
    height: auto;
    margin-top: 10px;
    list-style: none;
}
.zhan-tuirs ul li{
    width: 400px;
    height: 100px;
    margin-top: 20px;
}
.zhan-tuirs ul li:nth-child(1){
    margin-top: 0px;
}
.zhan-tuirs ul li img{
    margin-left: -200px;
    cursor: pointer;
}
.zhan-tuirs ul li .tui-jian{
    width: 150px;
    height: 100%;
    margin-left: 180px;
    margin-top: -102px;
}
.zhan-tuirs ul li .tui-jian p{
    width: 100%;
    height: 30px;
    text-align: left;
    line-height: 30px;
    margin-top: 3px;
}
</style>