<template>
  <div class="cpp_player">
       <div class="zhan-zuo">
            <div class="naotous">
                <div class="poyuans"><img :src="gh.work_tiny_logo" alt="" width="100%" height="100%"></div>
                <div class="loms">{{ gh.works_name }}</div>
                <div class="pooiss">{{ gh.work_name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ gh.work_time }}</div>
            </div>
            <div class="shability">
                <div class="shi-zuo">
                    <iframe src="/scratch/cpp_tui.html"  height="75%"></iframe>
                    <ul class="mygos">
                        <li>              
                            <img
                            :src="qingshen"
                            alt=""
                            />
                            <span class="shaomo">{{ gh.work_watch }}</span>
                        </li>
                        <li>
                            <img
                                :src="songshen"
                                alt=""
                            />
                            <span class="shaomo">{{ gh.work_comment }}</span>
                        </li>
                        <li>
                            <img
                            :src="aishen"
                            alt=""
                            />
                            <span class="shaomo">{{ gh.work_prefer }}</span>
                        </li>
                    </ul>
                </div>
                <div class="zhan-beizhu">
                    <div class="zhan-img">
                        <img :src="gh.work_tiny_logo" alt="">
                        <div class="zhan-ji">{{ gh.work_name }}</div>
                        <div class="zhan-nian">{{ gh.work_age }}岁&nbsp;&nbsp;&nbsp;{{ gh.work_grade }}年级&nbsp;&nbsp;&nbsp;学习时长{{ gh.work_studytime }}月</div>
                    </div>
                    <div class="zan-cont">
                        <textarea name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <div class="han-cont">
                        <textarea name="" id="" cols="30" rows="10"></textarea>
                    </div>
                </div>
            </div>
            
            <div class="zhan-ping">
                <Info />
                <div class="pinglun">
                    <div class="qing-las">
                       <div class="ping-yuan"><img :src="gh.work_tiny_logo" alt="" width="100%" height="100%"></div>
                       <input type="text" v-model="inputvalue">
                       <div class="ping-dian" @click="fabiao(gh.work_id)">发表评论</div>
                    </div>
                    <ul v-if="youta">
                        <li v-for="(ping,index) in lun" :key="index">
                            <div class="guying">
                                <img :src="gh.work_tiny_logo" alt="">
                            </div>
                            <div class="dameng">
                                <p style="font-size:14px;">{{ gh.work_name }}</p>
                                <p style="font-size:16px;margin-left:10px; ">{{ ping.comment_content }}</p>
                                <p style="font-size:12px;color: grey;">{{ ping.comment_time }}</p>
                            </div>
                            
                        </li>
                    </ul>
                    <div class="meiyou" v-if="meiyou">
                        <img src="../../assets/未标题-1.jpg" alt="">
                    </div>
                     <el-pagination
                        class="madan"
                        v-if="shuzui"
                        :current-page.sync="current_page"
                        background
                        layout="prev, pager, next"
                        :total=total
                        :page-size="10"
                    >
                    </el-pagination>
                    <div class="qings-las">
                       <div class="pings-yuan"><img :src="gh.work_tiny_logo" alt="" width="100%" height="100%"></div>
                       <input type="text" v-model="inputvalue">
                       <div class="pings-dian" @click="fabiao(gh.work_id)">发表评论</div>
                    </div>
                </div>
            </div>
       </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from "axios";
export default {
    components:{
        Info: () => import(`./../public_a/recommend`)
    },
    name: "cpp_player",
    data(){
        return {
            gh:{},
            duan:[],
            lun:[],
            inputvalue:'',
            youta:true,
            total:this.total,
            current_page: 1,
            meiyou:false,
            shuzui:true,
            qingshen:'',
            songshen:'',
            aishen:''
        }
    },
    watch: {
        current_page(to, from) {
            if (from != 0) {
                //搜索分页
                this.qinglunm(this.tabsNames)
            }
        },
    },
    created(){
        this.imd()
    },
    mounted(){
        window.scrollTo(0,0);
        this.gh=JSON.parse(Cookies.get("two")) 
        console.log(this.gh)
        this.zhanshows()
        this.shishow()
        this.qinglunm(this.tabsNames)
    },
    methods:{
        imd(){
            this.qingshen = this.$guide.url + "/works-display/kan.png"
            this.songshen = this.$guide.url + "/works-display/pinlun.png"
            this.aishen = this.$guide.url + "/works-display/zan.png"
        },
         zhanshows() {
            axios
                .get(this.$guide.api + "/infoshow_index/")
                // 接口地址
                .then(res => {
                    this.items = res.data.context.index_image_info;
                });
        },
         shishow(){
            axios
                .get(this.$guide.api + "/infoshow_work_recommend/")
                    .then(res => {
                        this.duan = res.data.context.workpage_list_recommend;
                    })
        },
        fabiao(c){
            var a = Cookies.get("xiaojiejie")
            if(a){
                var userid =Cookies.get("userid")
                axios
                    .get(this.$guide.api + "/infoshow_work_addcomment/?works_id=" + c + "&user_id=" + userid +"&floor=" + 1 + "&comment_content=" + this.inputvalue + "&comment_id_f1=" + -1 )
                        .then(res => {
                            console.log(res)
                            if(res.data.code == 0){
                                this.inputvalue = ""
                            }
                        })
            }else {
                alert("登录之后才能评论，请登录！")
            }
        },
        qinglunm(){
            axios   
                .get(this.$guide.api + `/infoshow_work_commentstatus/?works_id=${this.gh.work_id}&current_page=${this.current_page}&page_size=10`)
                    .then(res =>{
                        if(res.data.code == 5003){
                            this.meiyou = true
                            this.shuzui = false
                        } else {
                            this.lun = res.data.context.current_page_comment.page_comment_list
                            console.log(res.data.context.current_page_comment.page_comment_list)
                            this.total = res.data.context.summary_comment
                        }
                    })
        }
    }
};
</script>

<style>
*{
    margin: 0;
    padding: 0;
}
.cpp_player{
    width: 100%;
    height: auto;
}
.zhan-zuo{
    width: 62%;
    height: auto;
    margin-left: 19%;
    margin-top: 150px;
}
.shability{
    width: 100%;
    height: auto;
}
.shi-zuo{
    width: 100%;
    height: auto;
}
.shi-zuo iframe{
    width: 60%;
    height: 470px;
    margin-left: -40% !important;
    border: 0px;
}
.shi-zuo .mygos{
    width: 60%;
    height: 50px;
    margin-left: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.shi-zuo .mygos li{
    margin-left: 25%;
    line-height: 50px;
}
.shi-zuo .mygos li:nth-child(1){
    margin-left: 15%;
}
.shi-zuo .mygos li img{
    margin-top: 12px;
}
.shaomo{
    position: relative;
    margin-left: 10px;
    top: -6px;
}
.zhan-beizhu{
    width: 36%;
    height: 535px;
    margin-left: 63%;
    margin-top: -535px;
}
/* 左边备注 */
.naotous{
    width: 80%;
    height: 80px;
    margin-left: 3%;
}
.naotous .poyuans{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 15px;
    cursor: pointer;
}
.loms{
    width: 120px;
    height: 30px;
    font-size: 18px;
    margin-top: -50px;
    margin-left: 50px;
}
.pooiss{
    width: 200px;
    height: 30px;
    font-size: 14px;
    margin-left: 40px;
}
/* 左边备注 */
.zhan-beizhu .zhan-img{
    width: 300px;
    height: 90px;
}
.zhan-beizhu .zhan-img img{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-left: -210px;
}
.zhan-beizhu .zhan-img .zhan-ji{
    width: 120px;
    height: 30px;
    margin-left: 110px;
    margin-top: -80px;
    font-size: 16px;
    color: #333333;
}
.zhan-beizhu .zhan-img .zhan-nian{
    width: 190px;
    height: 30px;
    margin-left: 110px;
    margin-top: 0px;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
}
.zhan-beizhu .zan-cont{
    width: 100%;
    height: 190px;
    margin-top: 30px;
}
.zhan-beizhu .zan-cont textarea{
    width: 100%;
    height: 100%;
    border: 0px;
    background-color: #e2edff;
}
.zhan-beizhu .han-cont{
    width: 100%;
    height: 190px;
    margin-top: 30px;
}
.zhan-beizhu .han-cont textarea{
    width: 100%;
    height: 100%;
    border: 0px;
    background-color: #e2edff;
}
 .zhan-ping{
    width: 100%;
    height: auto;
}
.zhan-ping .pinglun{
    width: 61.4%;
    height: auto;
    margin-top: -1030px;
}   

/* 下面评论功能 */
.qing-las{
    width: 100%;
    height: 90px;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    margin-top: 10px;
}
.ping-yuan{
    width: 50px;
    height: 50px;
    margin-top: 20px;
}
.ping-yuan img{
    border-radius: 50%;
}
.qing-las input{
    width: 80%;
    height: 50px;
    background: #f4f5f7;
    border: 0px;
    position: relative;
    top: -50px;
    left: 0px;
}
.ping-dian{
    width: 50px;
    height: 50px;
    background: #00c0ff;
    font-size: 18px;
    position: relative;
    font-family: "楷体";
    top: -100px;
    left: 92%;
    cursor: pointer;
}
/* ///////// */
.qings-las{
    width: 100%;
    height: 90px;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    margin-top: 30px;
}
.pings-yuan{
    width: 50px;
    height: 50px;
    margin-top: 20px;
}
.pings-yuan img{
    border-radius: 50%;
}
.qings-las input{
    width: 80%;
    height: 50px;
    background: #f4f5f7;
    border: 0px;
    position: relative;
    top: -50px;
    left: 0px;
}
.pings-dian{
    width: 50px;
    height: 50px;
    background: #00c0ff;
    font-size: 18px;
    position: relative;
    font-family: "楷体";
    top: -100px;
    left: 92%;
    cursor: pointer;
}
.zhan-ping .zhan-tuirs{
    margin-left: 65%;
    margin-top: 50px;
}
</style>
