<template>
    <div class="register">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item prop="name">
                <el-input v-model="ruleForm.name" placeholder="请填写用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input v-model="ruleForm.password" placeholder="设置密码"></el-input>
            </el-form-item>
            <el-form-item prop="passwords">
                <el-input v-model="ruleForm.passwords" placeholder="确认密码"></el-input>
            </el-form-item>
            <el-form-item prop="phoned">
                <el-input v-model="ruleForm.phoned" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item prop="phoned_code" class="mengxing">
                <el-input v-model="ruleForm.phoned_code" placeholder="请输入验证码"></el-input>
                <el-button type="info" id="small" @click="yanzhneg" :disabled="secdisabled">{{content}}</el-button>
            </el-form-item>
            <el-form-item prop="type">
                <el-checkbox-group v-model="ruleForm.type">
                    <el-checkbox  name="type">我已阅读并同意<a href="">用户协议</a>和<a href="">隐私协议</a></el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')" class="zhuce">注册</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'register',
    data() {
        return {
            content: '发送验证码',  // 按钮里显示的内容
            secdisabled: false,
            ruleForm: {
                name: '',
                password:'',
                passwords:'',
                phoned:'',
                phoned_code:'',
                type:[]
            },
            rules: {
                name: [
                    { required: true, message: '请填写用户名字', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' }
                ],
                passwords: [    
                    { required: true, message: '请确认密码', trigger: 'blur' },
                    { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' }
                ],
                phoned: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max: 11, message: '长度为 11 个字符', trigger: 'blur' }
                ],
                phoned_code: [
                    { required: true, message: '请输入手机验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '长度为 6 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    methods:{
        yanzhneg(){
            if(this.ruleForm.phoned == '' || this.ruleForm.phoned == null){
                this.secdisabled = false;
                alert("请填写手机号")
            } else if(this.ruleForm.phoned != ''){
                var u = 60;
                var timer = setInterval(() => {
                    if(u <= 0){
                        clearInterval(timer)
                        this.secdisabled = false;
                        this.content = "获取验证码"
                    }else{
                        u--;
                        this.secdisabled = true;
                        this.content = u + "s后重新发送"
                    }
                }, 1000);
                axios.get(this.$untils.urls +'/send_sms?mobile=' + this.ruleForm.phoned + '&template_type=1')
                .then(res => {
                    if(res.data.code == '10'){
                        alert("获取验证码成功")
                    }else{
                        alert("获取验证码失败")
                    }
                })
            }
            
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                axios.get( this.$untils.api + '/register/?username=' +  this.ruleForm.name + '&mobile=' +  this.ruleForm.phoned + '&sms_code=' +  this.ruleForm.phoned_code + '&password=' +  this.ruleForm.password) 
                    .then(res => {
                        if(res.data.code == 0){
                            alert("注册账号成功请前往登录吧")
                            this.$router.push('login')
                        }else{
                            alert("注册账号失败，请检查所填写信息是否正确")
                        }
                        if(res.data.code == null){
                            alert("注册账号失败，请检查所填写信息是否正确")
                        }
                    })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        
    }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
.register{
    width: 100%;
    height: 937px;
    background-image: url("./../assets/学生登录页面psd.png");
    background-size: 100% 100%;
    position: relative;
}
.register .el-form{
    width: 500px;
    height: 500px;
    position: absolute;
    background: white;
    top: 200px;
    left: 55.2%;
    border-radius: 10px;
}
.register .el-form .el-form-item{
    width: 85% !important;
    margin-top: 20px;
}
.register .el-form .el-form-item:nth-child(1){
    margin-top: 50px;
}
.register .el-form .el-form-item .el-form-item__content{
    margin-left: 0px !important;
}
.register .el-form .el-form-item:nth-child(5){
    width: 55% !important;
    margin-top: 20px;
}
.register .el-form .el-form-item:nth-child(6){
    margin-bottom: 0px !important;
    margin-top: -50px;
}
.register .el-form .el-form-item:nth-child(7) .zhuce{
    width: 60% !important;
    height: 40px !important;
}
/* .register .el-form-item{
    position: relative;
    margin-left: 0px !important;
} */
.register #small {
    width: 80%;
    height: 40px !important;
    border: #3a8ee6;
    background: #3a8ee6;
    position: relative;
    top: -40px !important;
    left: 95%;
}
.register .is-disabled{
    background: #909399 !important;
}
.register .el-checkbox-group{
    margin-top: 10px;
    margin-left: 0px !important;
    text-align: left;
}
</style>