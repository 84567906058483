<template>
    <div class="logins">
        <div class="first">
            <span>
                <p><a href="/login">密码登录</a> </p>
                <p class="passlu"><a href="logins">短信登录</a></p>
            </span>
           <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item  prop="mobile">
                    <el-input type="mobile" v-model="ruleForm.mobile" placeholder="手机号" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item prop="sms_code">
                    <el-input type="sms_code" v-model="ruleForm.sms_code" placeholder="验证码" class="code"></el-input>
                    <el-radio-group  size="small" id="smalls">
                        <el-button type="info" @click="ma" :disabled="secdisabled"  style="text-align:center;">{{content}}</el-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
            <div class="fuzhu">
                <div class="wanliu">
                    <p class="wangpass"><a href="/find_password">忘记密码</a></p>
                    <div class="gun"></div>
                    <p class="login-zhu" ><a href="/register" style="color:black;">注册</a></p>
                </div>
            </div>
            
        </div>
    </div>
</template>


<script>
import axios from "axios";
import Cookies from 'js-cookie'
export default {
    name: 'login',
    data() {
        var validatemobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            }else if(!/^1[34578]\d{9}$/.test(this.ruleForm.mobile)){
                alert("请填写正确的手机号")
            }else{
                callback();
            }
        };
        var validatesms_code = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入验证码'));
            // } else if(!/^[1234567890]\d{6}$/.test(this.ruleForm.sms_code)){
            //     alert("请填写正确的验证码")
            } else {
                callback();
            }
        };
        return {
           content: '发送验证码',  // 按钮里显示的内容
            secdisabled: false,
            ruleForm: {
                mobile: '',
                sms_code: '',
            },
            rules: {
                mobile: [
                    { validator: validatemobile, trigger: 'blur' }
                ],
                sms_code: [
                    { validator: validatesms_code, trigger: 'blur' }
                ],
            
            }
        };
    },
    created() {
        this.check()
    },
    methods:{
        ma(){
            if(this.ruleForm.mobile != ''){
                var u = 60;
                var timer = setInterval(() => {
                    if(u <= 0){
                        clearInterval(timer)
                        this.secdisabled = false;
                        this.content = "获取验证码"
                    }else{
                        u--;
                        this.secdisabled = true;
                        this.content = u + "s后重新发送"
                    }
                }, 1000);
                axios.get(this.$untils.urls + '/send_sms?mobile=' + this.ruleForm.mobile + '&template_type=2')
                    .then(res => {
                        console.log(res);
                    })
            }else{
                alert("手机号不能为空")
            }
           
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios.get(this.$untils.api + '/login_code/?mobile=' +  this.ruleForm.mobile + '&sms_code=' +  this.ruleForm.sms_code) 
                        .then(res => {
                            if(res.data.code == 0){
                                this.spidc = res.data.context.username
                                this.spids = res.data.context.token
                                alert("短信验证码登录成功!")
                                if(this.spidc == '' || this.spids == ''){
                                    this.$router.push({path:'/login'})
                                }else{
                                    this.$router.push({path:'/'})
                                    Cookies.set('username',this.spidc,{ expires: 1, path: '/' })
                                    Cookies.set('token',this.spids,{ expires: 1, path: '/' })
                                }
                            }
                            if(res.data.code == '4001'){
                                alert("短信验证码有误")
                            }
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        
        zhu(){
            this.$router.push({path:'/register'})
        }
    }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
.header{
    display: none;
}
.logins{
    width: 100%;
    height: 900px;
    background-image: url("./../assets/学生登录页面psd.png");
    background-size: 100% 100%;
    position: relative;
}
.first{
    width: 400px;
    height: 370px;
    background: white;
    border-radius: 10px;
    position: absolute;
    top: 270px;
    left: 55.2%;
}
.first span{
    display: block;
    width: 80%;
    height: 70px;
    border-bottom: 1px solid gainsboro;
    margin-left: 10%;
    color: #333333;
    font-size: 20px;
}
.first span p{
    width: 50%;
    height: 70px;
    text-align: center;
    line-height: 70px;
}
.first span .passlu{
    border-bottom: 1px solid skyblue !important;
}
.first span p:last-child{
    margin-left: 50%;
    margin-top: -70px;
}
.el-form{
    width: 100%;
    height: 190px;
    margin-top: 40px;
}
.el-form-item{
    width: 100%;
    height: 40px;
    margin-left: -10%;
    margin-top: 20px !important;
}
.el-form-item:nth-child(1){
    margin-top: 0px !important;
}
.el-form-item:nth-child(3){
    width: 80%;
    margin-left: -2% !important;
}
.el-input__inner{
    width: 100%;
    height: 40px;
}
.login-img{
    position: relative;
    left: 175px;
    top: -35px;
}
.first .el-button{
    width: 100%;
    height: 40px;
    position: relative;
    top: 0px !important;
    margin-left: 5% !important;
}
.fuzhu{
    width: 100%;
    height: 30px;
    margin-top: 10px;
}
.wanliu{
    width: 130px;
    height: 30px;
    font-size: 14px;
    font-family: "微软雅黑";
    color: black;
    margin-top: -30px;
    margin-left: 60%;
}
.wanliu p{
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
.wanliu .login-zhu{
    margin-left: 65px;
    margin-top: -23px;
}
.wanliu div{
    width: 2px;
    height:15px;
    border-left: 1px solid grey;
    margin-left: 75px;
    margin-top: -23px;
}

/* 后续样式 */
.code{
    width: 50%;
    /* left: -60px !important; */
    text-align: left !important;
}
.logins #smalls{
    width: 45% !important;
    margin-left: 3% !important;
}
.el-button--info{
    background: #3a8ee6 !important;
    border: #3a8ee6;
}

.logins .is-disabled{
    background: #909399 !important;
    text-align: center !important;
}
.wanliu a{
    color: black;
    font-size: 12px !important;
}
</style>

