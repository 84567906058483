<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Cookies from "js-cookie";
// import axios from "axios";
export default {
  name: "App",
  data(){
    return{
      seen:false,
      name:'',
      faker:true,
      uzi: false,
      tes:false
    }
  },
  created(){
    this.inshow()
    this.name = Cookies.get("names")
  },
  methods:{
    //跳转页面
    links(){
      this.$router.push({path:'/'})
    },
    dian(){
      this.seen = false
    },
    outStyle(){
      this.seen = true
    },
    login(){
      this.$router.push({path:'login'})
    },
    zhcues(){
      this.$router.push({path:'/register'})
    },
    dsda() {
      window.open('http://111.229.214.104:8085/scratch3/index.html?scene=create');
    },
    inshow(){
     var a = Cookies.get("xiaojiejie")
      if(a){
          this.faker = false
          this.uzi = true
      } else {
          this.faker = true
          this.uzi = false
      }
    },
    wegame(){
      this.tes = true
    },
    skt(){
       this.tes = false
    },
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
header {
  width: 100%;
  height: 100px;
  background: rgb(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  z-index: 9999999999;
  text-align: left !important;
}
header .oneone{
  width: 100%;
  height: 100px;
  position: relative;
  left: 20.5%;
}
header .oneone img {
   width: auto;
  height: auto;
	max-width: 100%;
	max-height: 100%;
  position: absolute;
  left: -10%;
  top: 14px;
  cursor: pointer;
}

.denghou-img{
  width: 25px;
  height: 25px;
  border: 1px solid gainsboro;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: 10px;
}
.denghou-img img{
  position: relative;
  left: 0px;
  top: -10px;
}
.deng-hou>.denghou-name{
  width: 100px;
  height: 50px;
  margin-left: 50px;
  margin-top: -40px;
  cursor: pointer;
  position: relative;
  z-index: 9999999999;
}
</style>
