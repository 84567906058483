<template>
    <div class="login">
        <div class="first">
            <span>
                <p class="passlu"><a href="/login">密码登录</a> </p>
                <p><a href="logins">短信登录</a></p>
            </span>
             <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item prop="username">
                    <el-input v-model="ruleForm.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                            <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码" ></el-input>
                        </el-form-item>
                <el-form-item class="legao">
                    <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
                </el-form-item>
            </el-form>
            <div class="fuzhu">
                <div class="wanliu">
                    <p class="wangpass"><a href="/find_password" style="font-size:13px  !important;color:black  !important;">忘记密码</a></p>
                    <div class="gun"></div>
                    <p class="login-zhu" ><a href="/register" style="color:black;font-size:13px !important;">注册</a></p>
                </div>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie'
export default {
    name: 'login',
    data() {
        return {
            ruleForm: {
                username:'',
                password:'',
                type:[]
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 8, max: 20, message: '长度为 8 到 20 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 8, max: 20, message: '密码长度最少8位', trigger: 'blur' }
                ],
            },
            url:''
        };
    },
    methods:{
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios.get( this.$untils.api + '/login_username/?username=' +  this.ruleForm.username + '&password=' +  this.ruleForm.password) 
                        .then(res => {
                            if(res.data.code == 0){
                                this.spidc = res.data.context.username
                                this.spids = res.data.context.token
                                if(this.spidc == '' || this.spids == ''){
                                    alert("登录失败，请查看账号密码是否错误")
                                    this.$router.push('login')
                                }else{
                                    alert("登陆成功")
                                    Cookies.set('username',this.spidc,{ expires: 1, path: '/' })
                                    Cookies.set('token',this.spids,{ expires: 1, path: '/' })
                                    this.$router.push({path:'/'})
                                }
                            }
                            if(res.data.code == null || res.data.code == ''){
                                console.log(res.data.code)
                                alert("登录失败，请查看账号密码是否错误")
                                this.$router.push('login')
                            }
                        })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
.header{
    display: none;
}
.login{
    width: 100%;
    height: 900px;
    /* border: 1px solid black; */
    background-image: url("./../assets/学生登录页面psd.png");
    background-size: 100% 100%;
    position: relative;
}
.first{
    width: 400px;
    height: 370px;
    background: white;
    border-radius: 10px;
    position: absolute;
    top: 270px;
    left: 55.2%;
}
.first span{
    display: block;
    width: 80%;
    height: 70px;
    border-bottom: 1px solid gainsboro;
    margin-left: 10%;
    color: #333333;
    font-size: 20px;
}
.first span p{
    width: 50%;
    height: 70px;
    text-align: center;
    line-height: 70px;
}
.first span .passlu{
    border-bottom: 1px solid skyblue !important;
}
.first span p:last-child{
    margin-left: 50%;
    margin-top: -70px;
}
.el-form{
    width: 100%;
    height: 190px;
    margin-top: 40px;
}
.el-form-item{
    width: 100%;
    height: 40px;
    margin-left: -10%;
    margin-top: 20px !important;
}
.el-form-item:nth-child(1){
    margin-top: 0px !important;
}
.el-form-item:nth-child(3){
    width: 80%;
    margin-left: 0%;
}
.el-input__inner{
    width: 100%;
    height: 40px;
}
.login-img{
    position: relative;
    left: 175px;
    top: -35px;
}
.first .el-button{
    width: 100%;
    height: 40px;
    position: relative;
    top: 0px !important;
    margin-left: 0px !important;
}
.fuzhu{
    width: 100%;
    height: 30px;
    margin-top: 10px;
}

.wanliu{
    width: 130px;
    height: 30px;
    font-size: 14px;
    font-family: "微软雅黑";
    color: black;
    margin-top: -30px;
    margin-left: 60%;
}
.wanliu p{
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
.wanliu .login-zhu{
    margin-left: 65px;
    margin-top: -23px;
}
.wanliu div{
    width: 2px;
    height:15px;
    border-left: 1px solid grey;
    margin-left: 75px;
    margin-top: -23px;
}
</style>