<template>
    <div class="download">
        <div class="onload">
            <div class="scratch">
                <div class="scratch_tou">
                    <img :src='this.$guide.url + "/technical-support/Download-Center/logkit.png"' alt="">
                    <p>图形化编程</p>
                </div>
                <div class="yangtai" v-for="(item,index) in items" :key="index">
                    <div class="ima">
                        <img :src="item.download_img_url" alt="">
                    </div>
                    <p style="font-size:20px;margin-top:-80px">{{ item.download_title }}</p>
                    <p style="font-size:16px;margin-top:20px">{{ item.download_introduce }}</p>
                    <div class="wenti"><a href="">安装遇到问题？</a></div>
                    <ul>
                        <li @click="scratchwin(item.download_win_url)"><img :src="window_img" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                        <li @click="scratchwins(item.download_macos_url)"><img :src="img_url" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                    </ul>
                </div>
            </div>
            <div class="python">
                <div class="scratch_tou">
                    <img :src='$guide.url + "/technical-support/Download-Center/Code-management.png"' alt="">
                    <p>代码编程</p>
                </div>
                <div class="yangtai" v-for="(itema,index) in item" :key="index">
                    <div class="ima">
                        <img :src="itema.download_img_url" alt="">
                    </div>
                    <p style="font-size:20px;margin-top:-80px">{{ itema.download_title }}</p>
                    <p style="font-size:16px;margin-top:20px">{{ itema.download_introduce }}</p>
                    <div class="wenti"><a href="">安装遇到问题？</a></div>
                    <ul>
                        <li @click="pythonwin(itema.download_win_url)"><img :src="window_img" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                        <li  @click="pythonwins(itema.download_macos_url)"><img :src="img_url" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                    </ul>
                </div>
                <div class="fenxiang" v-for="(ai,index) in shuo" :key="index">
                    <div class="ima">
                        <img :src="ai.download_img_url" alt="">
                    </div>
                    <p style="font-size:20px;margin-top:-80px">{{ ai.download_title }}</p>
                    <p style="font-size:16px;margin-top:20px">{{ ai.download_introduce }}</p>
                    <div class="wenti"><a href="">安装遇到问题？</a></div>
                    <ul>
                        <li @click="cppwin(ai.download_win_url)"><img :src="window_img" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                    </ul>
                </div>
            </div>
             <div class="cpp">
                <div class="cpp_tou">
                    <img :src='this.$guide.url + "/technical-support/Download-Center/application-2.png"' alt="">
                    <p>其他应用</p>
                </div>
                <div class="yangtai" v-for="(vscodes,index) in vscode" :key="index">
                    <div class="ima">
                        <img :src="vscodes.download_img_url" alt="">
                    </div>
                    <p style="font-size:20px;margin-top:-80px">{{ vscodes.download_title }}</p>
                    <p style="font-size:16px;margin-top:20px">{{ vscodes.download_introduce }}</p>
                    <div class="wentis"><a href="">安装遇到问题？</a></div>
                    <ul>
                        <li @click="vscodewin(vscodes.download_win_url)"><img :src="window_img" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                        <li @click="vscodemac(vscodes.download_macos_url)"><img :src="img_url" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                    </ul>
                </div>
                <div class="yangtai" v-for="(yi,index) in huai" :key="index">
                    <div class="ima">
                        <img :src="yi.download_img_url" alt="">
                    </div>
                    <p style="font-size:20px;margin-top:-80px">{{ yi.download_title }}</p>
                    <p style="font-size:16px;margin-top:20px">{{ yi.download_introduce }}</p>
                    <div class="wenti"><a href="">安装遇到问题？</a></div>
                    <ul>
                        <li @click="chromewin(yi.download_win_url)"><img :src="window_img" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                        <li @click="chromemac(yi.download_macos_url)"><img :src="img_url" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                    </ul>
                </div>
                <div class="yangtai" v-for="(flashs,index) in flash" :key="index">
                    <div class="ima">
                        <img :src="flashs.download_img_url" alt="">
                    </div>
                    <p style="font-size:20px;margin-top:-80px">{{ flashs.download_title }}</p>
                    <p style="font-size:16px;margin-top:20px">{{ flashs.download_introduce }}</p>
                    <div class="wenti"><a href="">安装遇到问题？</a></div>
                    <ul>
                        <li @click="chromewin(flashs.download_win_url)"><img :src="window_img" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                        <li @click="chromemac(flashs.download_macos_url)"><img :src="img_url" alt=""><span>下&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "download",
    data() {
        return {
            img_url:'',
            window_img:'',
            items:[],
            item:[],
            huai:[],
            shuo:[],
            vscode:[],
            flash:[]
        }
    },
    created(){
        this.infoshows()
        this.cok()
    },
    mounted(){
        window.scrollTo(0,0);
    },
    methods:{
        cok(){
            this.img_url = this.$guide.url + "/technical-support/Download-Center/mac.png"
            this.window_img = this.$guide.url + "/technical-support/Download-Center/Windows.png"
            console.log(this.img_url)
        },
        infoshows() {
            axios
                .get(this.$guide.api + "/infoshow_download_url/")
                // 接口地址
                .then((res) => {
                    this.items = res.data.context.scratch;
                    this.item = res.data.context.python;
                    this.huai = res.data.context.chrome;
                    this.shuo = res.data.context.cpp;
                    this.vscode = res.data.context.vscode;
                    this.flash = res.data.context.flash;
                });
        },
        scratchwin(x){
            window.location.href= x
        },
        scratchwins(d){
            window.location.href= d
        },
        pythonwin(n){
            window.location.href= n
        },
        pythonwins(m){
            window.location.href= m
        },
        cppwin(b){
            window.location.href= b
        }, 
        chromewin(q){
            window.location.href= q
        },
        chromemac(z){
            window.location.href= z
        },
        vscodewin(w){
            window.location.href= w
        },
        vscodemac(h){
            window.location.href= h
        },
    }
}
</script>

<style>
    .download{
        width: 100%;
        height: auto;
        position: absolute;
        background: #EFF5FF;
        margin-top: 100px;
    }
    .onload{
        width: 64%;
        height: auto;
        margin-left: 18%;
        margin-top: 100px;
    }
    .onload .scratch{
        width: 100%;
        height: auto;
    }
    .onload .scratch .scratch_tou{
        width: 20%;
        height: 50px;
    }
    .onload .scratch .scratch_tou img{
        display: block;
        margin-left: 0px;
    }
    .onload .scratch .scratch_tou p{
        margin-left: 30%;
        margin-top: -50px;
        font-size: 24px;
        color: #000000;
        line-height: 50px;
    }
    .yangtai{
        width: 100%;
        height: 160px;
        background: white;
        border-radius: 20px;
        margin-top: 50px;
    }
    .yangtai .ima{
        width: 100px;
        height: 100px;
        position: relative;
        margin-left: 4%;
        position: relative;
        top: 25px;
    }
    .yangtai .ima img{
        width: 100%;
        height: 100%;
    }
    .yangtai p{
        width: 60%;
        text-align: left;
        margin-left: 17%;
    }
    .yangtai .wenti{
        margin-left: 40%;
        margin-top: 20px;
    }
    .yangtai ul{
        width: 120px;
        height: 100px;
        list-style: none;
        display: flex;
        flex-wrap: wrap ;
        margin-top: -120px;
        margin-left: 85%;
    }
    .yangtai ul li{
        width: 100%;
        height: 30px;
        border: 1px solid #5293ff;
        border-radius: 10px;
        margin-top: 40px;
        line-height: 30px;
        cursor: pointer;
    }
    .yangtai ul li:nth-child(1){
        margin-top: 0px;
    }
    .yangtai ul li img{
        display: block;
        margin-left: 15%;
        margin-top: 3px;
    }
    .yangtai ul li span{
        display: block;
        margin-left: 36%;
        margin-top: -27px;
    }



    .onload .python{
        width: 100%;
        height: auto;
        margin-top: 100px;
    }
    .onload .python .scratch_tou{
        width: 20%;
        height: 50px;
    }
    .onload .python .scratch_tou img{
        display: block;
        margin-left: 0px;
    }
    .onload .python .scratch_tou p{
        margin-left: 30%;
        margin-top: -50px;
        font-size: 24px;
        color: #000000;
        line-height: 50px;
    }
    .yangtai{
        width: 100%;
        height: 160px;
        background: white;
        border-radius: 20px;
        margin-top: 50px;
    }
    .fenxiang{
        width: 100%;
        height: 160px;
        background: white;
        border-radius: 20px;
        margin-top: 50px;
    }
    .fenxiang .ima{
        width: 100px;
        height: 100px;
        position: relative;
        margin-left: 4%;
        position: relative;
        top: 25px;
    }
    .fenxiang .ima img{
        width: 100%;
        height: 100%;
    }
    .fenxiang p{
        width: 60%;
        text-align: left;
        margin-left: 17%;
    }
    .fenxiang .wenti{
        margin-left: 40%;
        margin-top: 25px;
    }
    .yangtai .wentis{
        position: relative;
        margin-left: 40% ;
        margin-top: 35px ;
    }
    .fenxiang .leihao{
        margin-top: -145px !important;
    }
    .fenxiang ul{
        width: 120px;
        height: 100px;
        list-style: none;
        display: flex;
        flex-wrap: wrap ;
        margin-top: -125px;
        margin-left: 85%;
    }
    .fenxiang ul li{
        width: 100%;
        height: 30px;
        border: 1px solid #5293ff;
        border-radius: 10px;
        margin-top: 35px;
        line-height: 30px;
        cursor: pointer;
    }
    .fenxiang ul li img{
        display: block;
        margin-left: 15%;
        margin-top: 3px;
    }
    .fenxiang ul li span{
        display: block;
        margin-left: 36%;
        margin-top: -27px;
    }




    .onload .cpp{
        width: 100%;
        height: auto;
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .onload .cpp .cpp_tou{
        width: 20%;
        height: 50px;
    }
    .onload .cpp .cpp_tou img{
        display: block;
        margin-left: 0px;
    }
    .onload .cpp .cpp_tou p{
        margin-left: 30%;
        margin-top: -50px;
        font-size: 24px;
        color: #000000;
        line-height: 50px;
    }
    .yangtai{
        width: 100%;
        height: 160px;
        background: white;
        border-radius: 20px;
        margin-top: 50px;
    }
</style>