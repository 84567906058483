<template>
    <div id="curriculum_system">
         <!-- 轮播图 -->
        <el-carousel :interval="5000" arrow="always" :height="bannerHeight+'px'">
            <el-carousel-item v-for="(item,index) in items" :key="index">
                <div class="hoeme-img">
                    <img ref="bannerHeight" :src="item.lesson_image_url" alt="" @load="imgload" width="100%">
                </div>
            </el-carousel-item>
        </el-carousel>
        <!-- 课程介绍 -->
        <div class="kexi">
            <div class="kexi_cheng">
                <div><b> 参照美国CSTA标准 优化出更适合中国孩子的编程课</b></div>
                <p >快乐二进制课程参照美国计算机教师协会 K-12教育标准（CSTA K-12 CS standards）</p>
                <form>依据孩子学习需求，编写不同体系的阶梯式课程，制定更适合中国孩子的教学体系</form>
                <img :src='this.$guide.url + "/Curriculum-system/tixi.jpg"' alt="">
            </div>
        </div>
        <!-- 课程讲解 -->
        <div class="haonan"> 
            <span><b>详细的课程讲解,为孩子选择合适的课程</b></span>
            <ul>
                <li>
                    <div class="hao-one"><img :src='this.$guide.url + "/Curriculum-system/Scratch1.png"' alt=""></div>
                    <div class="hao-two"><img :src='this.$guide.url + "/Curriculum-system/scratch.png"' alt=""></div>
                </li>
                <li>
                    <div class="hao-fore"><img :src='this.$guide.url + "/Curriculum-system/python.png"' alt=""></div>
                    <div class="hao-there"><img :src='this.$guide.url + "/Curriculum-system/python1.png"' alt=""></div>
                </li>
                <li>
                    <div class="hao-one"><img :src='this.$guide.url + "/Curriculum-system/C%2B%2B.png"' alt=""></div>
                    <div class="hao-two"><img :src='this.$guide.url + "/Curriculum-system/cpp.png"' alt=""></div>
                </li>
            </ul>
            <div class="fengxi"></div>
        </div>
        <!-- 底部内容 -->
        <div class="dibu">
            <div class="top">
                <p><b>学习编程的好处</b></p>
                <p>编程让孩子学会通过自己的想法，来探索，尝试解决问题。</p>
                <p>让孩子能够尽情的表达自己的想法，并掌握分析问题，解决问题的能力</p>
            </div>
            <div class="xue-one">
                <img :src='this.$guide.url + "/Curriculum-system/good.jpg"' alt="">
            </div>
            <div class="die"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  name: "curriculum_system",
    data() {
        return {
            bannerHeight:'',
            items:[],
        }
    },
    mounted() {
         window.scrollTo(0,0);
        this.infoshow();
        this.imgload();
        window.addEventListener('resize',() =>{
            this.bannerHeight=this.$refs.bannerHeight[0].height
            this.imgload();
        },false)
    },
    methods:{
        infoshow() {
            axios
                .get(this.$guide.api + "/infoshow_lesson_image/")
                // 接口地址
                .then(res => {
                    this.items = res.data.context.lesson_image_info;
                    console.log(res.data.context.lesson_image_info)
                });
        },
        imgload(){
            this.$nextTick(()=>{
                this.bannerHeight=this.$refs.bannerHeight[0].height
            })
        }
    }
};
</script>

<style>
#curriculum_system{
    width: 100%;
    height: auto;
    position: relative;
    top: 100px;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
  
.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
.kexi{
    width: 100%;
    height: auto;
}
.kexi_cheng{
    width: 62.5%;
    height: auto;
    margin-left: 18.5%;
}
.kexi_cheng div{
    width: 100%;
    height: 50px;
    font-size: 28px;
    margin-top: 100px;
}
 .kexi_cheng p{
     width: 100%;
    margin-top: 30px;
    text-align: center;
    font-size: 22px;
}
 .kexi_cheng form{
    width: 100%;
    margin-top: 10px;
    text-align: center;
    font-size: 22px;
}
 .kexi_cheng img{
     width: 100%;
     height: 100%;
    display: block;
    margin-top: 70px;
}
.haonan{
    width: 100%;
    height: auto;
    background: #eff5ff;
    margin-top: 100px;
}
.haonan .fengxi{
    width: 100%;
    height: 50px;
}
.haonan span{
    display: block;
    font-size:28px;
    padding-top: 50px;
}
.haonan ul{
    list-style: none;
    width: 62.5%;
    height: auto;
    list-style: none;
    position: relative;
    left: 18.5%;
}
.haonan ul li{
    width: 100%;
    height: 400px;
    margin-top: 70px;
}
.haonan ul li img{
    border-radius: 10px;
}
.haonan ul li:nth-child(1){
    margin-top: 50px;
}
.haonan ul li .hao-one{
    width: 56.6666%;
    height: 100%;
    background: white;
    border-radius: 10px;
}
.haonan ul li .hao-one img{
    width: 94%;
    height: 90%;
    border-radius: 15px;
    position: relative;
    top: 5% !important;
}
.haonan ul li .hao-two{
    width: 39.1%;
    height: 100%;
    margin-left: 60.5%;
    margin-top: -400px;
}
.haonan ul li .hao-two img{
    width: 100%;
    height: 100%;
}
.haonan ul li .hao-fore{
    width: 39.1%;
    height: 100%;
}
.haonan ul li .hao-fore img{
    width: 100%;
    height: 100%;
}
.haonan ul li .hao-there{
    width: 56.666%;
    height: 100%;
    margin-left: 43%;
    margin-top: -400px;
    background: white;
    border-radius: 10px;
}
.haonan ul li .hao-there img{
    width: 94%;
    height: 90%;
    border-radius: 15px;
    position: relative;
    top: 5% !important;
}


/* 学习编程的好处 */
.dibu{
    width: 100%;
    height: auto;
    margin-top: 100px;
}
.top{
    width: 100%;
    height: auto;
    margin-top: 80px;
}
.top p{
    margin-top: 10px;
    font-size: 22px;
}
.top p:nth-child(1){
    font-size: 28px !important;
}
.dibu .xue-one{
    width: 62.5%;
    height: 700px;
    margin-left: 18.5%;
    margin-top: 50px;
    list-style: none;
}
.dibu .xue-one img{
    width: 100%;
    height: 100%;
}
.die{
    width: 99.5%;
    height: 100px;
    border: 1px solid white;
}
</style>
