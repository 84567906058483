<template>
    <div class="about"> 
        <div class="about_as">
            <div class="as_guan">
                <p>公司简介</p>
                <p>COMP ANY PROFILE</p>
            </div>
        </div>
        <div class="top_yu">
            <p class="guan_wo">关于我们</p>
            <div class="guanwen">
                <p>
                    快乐二进制青少儿编程是中国知名少儿编程教育品牌。是专门为6-16岁青少儿提供的线下(面对面授课),线上（真人一对一
                    授课）趣味编程教育，目的是提升孩子智力，开拓思维，培养孩子成为全方位的高素质，高科技创新人才！
                </p>
                <p>
                    快乐二进制青少儿编程为全球6-16岁青少儿少儿研发了图形化编程平台及系列编程课。通过学习，孩子们可以创作出奇妙的游
                    戏、软件、动画等，全方位锻炼逻辑思维、创造力等核心素养。目前，快乐二进制青少儿编程平台已研发了超过19种品类编程课，
                    并与全国一流名牌大学，中学，小学等在内的全国11599余所学校与教育机构达成合作。
                </p>
                <p>2019年，快乐二进制青少儿编程被评选为年度影响力在线教育品牌！</p>
            </div>
        </div>
        <div class="context"> 
            <div class="context_a">
                <p class="kuai_a"><b>快乐二进制的优势</b></p>
                <div class="fang_one">
                        <ul>
                            <li>
                                <div class="box_wen">01</div>
                            </li>
                            <li>
                                <div class="box_wen">02</div>
                            </li>
                            <li>
                                <div class="box_wen">03</div>
                            </li>
                            <li>
                                <div class="box_wen">04</div>
                            </li>
                        </ul>
                </div>
                <ul class="vm_ul">
                    <li>
                        <img :src='this.$guide.url + "/about/Company-profile/advantage-1.png"' width="100%" height="100%">
                    </li>
                    <li>
                        <div class="left">
                            <div class="right_a"></div>
                            <span class="spanon">先进的教育体系</span>
                            <div class="left_a">
                                <p>课程参照美国计算机教师协会 K-12教育标准 </p>
                                <p>（CSTA K-12 CS standards） </p>
                                <p>依据孩子学习需求，编写不同体系的阶梯式课程，制定更适 </p>
                                <p>合中国孩子的教学体系</p>
                            </div>
                            
                        </div>
                    </li>
                    <li>
                        <div class="left">
                            <div class="right_a"></div>
                            <span>强大的师资团队</span>
                            <div class="left_a">
                                <p>来自各个名校、超大规模的讲师梦之队，给孩子一个更优质</p>
                                <p>更高效的学习课堂</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <img :src='this.$guide.url + "/about/Company-profile/advantage-2.png"' alt="">
                    </li>
                    <li>
                        <img :src='this.$guide.url + "/about/Company-profile/advantage-3.png"' alt="">
                    </li>
                    <li>
                        <div class="left">
                             <div class="right_a"></div>
                            <span>坚实的硬件设施</span>
                            <div class="left_a">
                                <p>科技化的教学工具，让学员可以跟着课堂讲解即时操作，更</p>
                                <p>快的对所讲的知识融会贯通。</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="left">
                             <div class="right_a"></div>
                            <span>优秀的学习资源</span>
                            <div class="left_a">
                                <p>快乐二进制给孩子提供最优质的学习资源，让孩子能够及时</p>
                                <p>的学习到最新的知识，创造更好的世界。</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <img :src='this.$guide.url + "/about/Company-profile/advantage-4.png"' alt="">
                    </li>
                </ul>
            </div>
        </div>
        <div class="news">
            <span><b>新闻动态</b></span>
            <ul class="liziqi">
                <li v-for="(information,index) in informations" :key="index">
                    <div class="new_img">
                        <img :src="information.news_image_url" alt="" height="100%" width="100%">
                    </div>
                    <div class="new_wenzi">
                        <span>{{ information.news_title }}</span>
                        <span>{{ information.news_content | ellipsis}}</span>
                        <div class="divs">
                            <p>时间：{{ information.news_year }}-{{ information.news_date }}</p>
                            <span @click="shiting(information.news_id)">
                                <div>查看内容</div>
                                <img :src="baoshen" alt="">
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
            <el-pagination
                :current-page.sync="current_page"
                background
                layout="prev, pager, next"
                :total=total
                :page-size=6
            >
            </el-pagination>
        </div>
    </div>
</template>


<script>
import axios from "axios";
export default {
    name: "about",
    data() {
        return {
            informations:[],
            total:this.total,
            current_page: 1,
            baosehn:''
        }
    },
    watch: {
        current_page(to, from) {
            if (from != 0) {
                //搜索分页
                this.infoshows()
            }
        },
    },
    created(){
         this.shens()
    },
    mounted() {
        this.infoshows();
         window.scrollTo(0,0);
    },
    methods: {
        shens(){
            this.baoshen = this.$guide.url + "/about/Company-profile/rightas.png"
        },
        infoshows() {
        axios
            .get(this.$guide.api + `/infoshow_new/?current_page=${this.current_page}&page_size=6`)
            // 接口地址
            .then(res => {
              this.informations = res.data.context.current_page_news.page_news_list;
              this.total = res.data.context.summary_news
              console.log(this.informations)
            });
        },
        shiting(s){
            window.location.href="/new/" + s + ".html"
        }
    },
    filters: {
    // 当标题字数超出时，超出部分显示’...‘。此处限制超出8位即触发隐藏效果
        ellipsis (value) {
            if (!value) return ''
            if (value.length > 95) {
                return value.slice(0, 95) + "...."
            }
            return value
        }
    }
}
</script>

<style >
.about{
    width: 100%;
    height: auto;
    position: relative;
}
.about_as{
    width: 100%;
    height: 700px;
    background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/about/Company-profile/background.png");
    position: absolute;
}
.as_guan{
    width: 250px;
    height: 100px;
    color: white;
    font-size: 30px;
    font-family: "微软雅黑";
    text-align: center;
    position: absolute;
    left: 45%;
    BOTTOM: 350px;
}
.as_guan p:nth-child(2){
    width: 250px;
    height: 30px;
    background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/about/Company-profile/about-b.png");
    margin-top: 30px;
    font-size: 20px !important; 
}
.top_yu{
    width: 60%;
    height: 550px;
    border-radius: 15px;
    background: white;
    position: absolute;
    z-index: 999999;
    font-family: "微软雅黑";
    top: 400px;
    left: 20.5%;
    box-shadow:4px 3px 20px 2px #cee1ff;
}
.top_yu .guan_wo{
    width: 200px;
    height: 50px;
    color: white;
    background-image: url("http://175.24.249.4/files/happy_binary_resource/official_website_pc/resource_static/about/Company-profile/about-a.png");
    text-align: center;
    line-height: 50px;
    font-size: 22PX;
    position: absolute;
    left: 42%;
    top: 50px;
}
.top_yu .guanwen{
    width: 85%;
    height: 400px;
    position: absolute;
    top: 114px;
    left: 7%;
    font-size: 18px;
    font-family: "微软雅黑";
    font-weight: 400;
    line-height: 35px;
}
.top_yu .guanwen p{
    margin-top: 36px;
    text-align: left;
    text-indent: 2.1em;
}
.context{
    width: 100%;
    height: 34.8rem;
    background: #eff5ff;
    position: absolute;
    top: 700px;
}

.context_a{
    width: 60%;
    height: 1595px;
    position: relative;
    left: 20.5%;
    top: 350px;
}
.context_a .kuai_a{
    font-size: 32px;
}
.context_a .vm_ul{
    width: 100%;
    height: 1400px;
    list-style: none;
    position: absolute;
    top: 80px;
}
.fang_one{
    width: 100%;
    height: 1400px;
    position: absolute;
    top: 145px;
}
.fang_one ul{
    width: 100%;
    height: 32.8rem;
    list-style: none;
    
}
.fang_one ul li{
    width: 100%;
    height: 6.4rem;
}
.fang_one ul li .box_wen{
    width: 10%;
    height: 100px;
    border: 2px solid white;
    background: #0060ff;
    position: relative;
    z-index: 99999;
    left: 45%;
    top: 56px;
    text-align: center;
    line-height: 100px;
    font-size: 24px;
    color: white;
}
.context_a .vm_ul li{
    width: 50%;
    height: 6.4rem;
    float: left;
}
.context_a .vm_ul li img{
   width: 100%;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.context_a .vm_ul li:nth-child(2){
    background: white;
}
.context_a .vm_ul li:nth-child(3){
    background: white;
}
.context_a .vm_ul li:nth-child(6){
    background: white;
}
.context_a .vm_ul li:nth-child(7){
    background: white;
}
.left{
    width: 420px;
    height: 180px;
    position: relative;
    top: 1.5rem;
    left: 1.5rem;
}
.spanon{
    position: relative;
    left: -145px !important;
}
.right_a{
    width: 50px;
    height: 10px;
    border-bottom: 2px solid #0060ff;
    position: absolute;
}
    .left span{
        font-size: 22px;
        color: #0060ff;
        position: relative;
        left: -135px;
        top: 20px;
    }
.left .left_a p{
    text-align: left;
    font-size: 16px;
    font-family: "微软雅黑";
    margin-top: 10px;
}
    .left .left_a{
    width: 6rem;
    position:relative;
    display:block;
    top:30px;
    }
.news{
    width: 100%;
    height: auto;
    background: #f8f8f8;
    position: absolute;
    top: 47rem;
}
.news .el-pagination{
    margin-top: -50px !important;
    margin-bottom: 30px !important;
    
}
.news span{
    font-size: 32px;
    display: block;
    margin-top: 90px;
}
.news .liziqi{
    width: 60%;
    height: auto;
    position: relative;
    left: 20%;
    top: 50px;
    list-style: none;
    margin-bottom: 200px;
}
.news .liziqi li{
    width: 100%;
    height: 180px;
    background: white;
    margin-top: 40px;
    border-radius: 0px 10px 10px 0px;
}
.news .liziqi li:first-child{
    margin-top: 0px;
}
.news .liziqi li .new_img{
    width: 26.5%;
    height: 180px;
    position: relative;
}
.new_wenzi{
    width: 70%;
    height: 180px;
    position: relative;
    margin-top: -183px;
    margin-left: 27%;
}
.new_wenzi span{
     text-align: left;
    display: block;
    margin-top: 30px;
    width: 90%;
    font-size: 16px;
    margin-left: 3%;
}
.new_wenzi span:first-child{
    display: block;
    margin-top: 20px;
    font-size: 20px;
    font-family: "黑体";
}
.divs{
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 17px;
}
.divs p{
    width: 25%;
    height: 25px;
    float: left;
    font-size: 12px;
}
.divs span{
    width: 120px;
    height: 25px;
    display: block;
    margin-top: -10px;
    margin-left: 80% !important;
    line-height: 25px;
    cursor: pointer;
}
.divs span img{
    position: relative;
    left: 80px;
    top: -22px;
}
</style>